import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import CustomBreadcrumb from "../../../../components/CustomBreadcrumb";
import { Tabs, Typography } from "@mui/material";
import CustomTabPanel from "../../../../components/CustomTabPanel";
import PolicyDocumentTabItem from "./PolicyDocumentTabItem";
import PolicyRelatedTable from "./PolicyRelatedTable";
import UsefulDocTable from "./UsefulDocTable";
import { bindActionCreators } from "redux";
import { policyDetailsActions } from "../../../../actions/policyDetails.action";
import LoadingPopup from "../../../../components/LoadingPopup";

class PolicyDocuments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTab: 1,
      filteredTabData: this.tabData
    };
  }

  tabData = [
    // { label: "Policy Related", value: 0 },
    { label: "Useful Documents", value: 1 }
  ];

  columns = [
    { field: "documentName", headerName: "Document Name" },
    { field: "product", headerName: "Product" },
    { field: "effectiveDate", headerName: "Effective Date" }
  ];

  componentDidMount() {
    // this.props.getPolicyRelatedDocumentList(this.props.policy.policyNo);
    // this.handleDocumentTabFilter();
  }

  handleDocumentTabFilter = () => {
    let filteredTabData = this.tabData;

    // wbm = whitelist by module
    // ams = accessible modules
    let whitelistByModuleEnabled = this.props.userState.auth.wbm.toLowerCase() === "true";

    if (whitelistByModuleEnabled) {
      const whitelistedPolicyInformationModule = this.props.userState.auth.ams.includes("POLICY_INFORMATION");
      filteredTabData = this.tabData.filter(data => !data.value == 0 || whitelistedPolicyInformationModule);
    }

    if (filteredTabData.length < 2) {
      this.setState({ currentTab: 1 });
    }

    this.setState({ filteredTabData: filteredTabData });
  };

  handleTabChange = (event, value) => {
    this.setState({ currentTab: value });
  };

  renderTabs() {
    const { classes } = this.props;
    const { currentTab } = this.state;

    return this.state.filteredTabData.map(tab => (
      <PolicyDocumentTabItem
        key={tab.value}
        label={tab.label}
        value={tab.value}
        currentTab={currentTab}
        activeClass={classes.tabActive}
        onChange={this.props.handleTabChange}
      />
    ));
  }

  render() {
    const { classes, policy, showLoadingPopup, policyDocument } = this.props;
    return (
      <>
        <div className={classes.root}>
          <LoadingPopup title="popup.loading.default.title" name="load-policy-listing" open={showLoadingPopup} />
          <div className={classes.breadcrumb}>
            <CustomBreadcrumb currentPage="PolicyDocuments" companyName={policy.companyName} />
          </div>
          <div className={classes.content}>
            <Typography className={classes.title}>
              {policy.companyName && `${policy.companyName}'s`} Documents
            </Typography>

            <Tabs
              value={this.state.currentTab}
              onChange={this.handleTabChange}
              centered
              variant="fullWidth"
              scrollButtons
              allowScrollButtonsMobile
              className={classes.tabs}
              TabIndicatorProps={{
                sx: {
                  height: 4
                }
              }}
            >
              {this.renderTabs()}
            </Tabs>
            <CustomTabPanel value={this.state.currentTab} index={0}>
              <PolicyRelatedTable header={this.columns} policyNo={policy.policyNo} documents={policyDocument} />
            </CustomTabPanel>
            <CustomTabPanel value={this.state.currentTab} index={1}>
              <UsefulDocTable displayType={policy.displayType} />
            </CustomTabPanel>
          </div>
        </div>
      </>
    );
  }
}

const styles = theme => ({
  root: {},
  maxWidth: {
    width: "100%"
  },
  breadcrumb: {
    margin: "10px 10px",
    paddingBottom: "20px"
  },
  content: {
    padding: "10px 40px"
  },
  tabs: {
    backgroundColor: "white !important"
  },
  tabActive: {
    fontWeight: "bold",
    color: "black !important"
  },
  title: {
    fontSize: "20px",
    fontWeight: "600 !important",
    paddingBottom: "20px",
    marginLeft: "10px"
  }
});

function mapStateToProps(state) {
  return {
    userState: state.user,
    policy: state.policyDetails.policy,
    policyDocument: state.policyDetails.relatedDocuments,
    showLoadingPopup: state.app.showLoadingPopup
  };
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(policyDetailsActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(PolicyDocuments));
