import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { withStyles } from "@mui/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableFooter from "@mui/material/TableFooter";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import Enums from "../../../../utils/Enums";
import { bindActionCreators } from "redux";
import { myEmployeeActions } from "../../../../actions";
import Util from "../../../../utils/Util";
import classNames from "classnames";
import Icon from "@mui/material/Icon";
import { Info } from "@mui/icons-material";
import InfoPopover from "../../../../components/InfoPopover";
import FormControl from "@mui/material/FormControl";
import Input from "@mui/material/Input";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import FilterIcon from "@mui/icons-material/FilterList";
import TablePaginationActions from "./TablePaginationActions";
import config from "../../../../config/config";
import { withRouter } from "react-router";

const EMPTY_SELECT_VALUE = "-";

class MyEmployeeTable extends Component {
  constructor(props) {
    super(props);
    this.columns = [
      {
        name: "full_name",
        type: "string",
        label: "Full Name",
        showInfo: false,
        filter: this.getTextFilter("full_name")
      },
      {
        name: "id_no",
        type: "string",
        label: "NRIC/FIN/Passport",
        showInfo: false,
        filter: this.getTextFilter("id_no")
      },
      {
        name: "no_of_dependent",
        type: "number",
        label: "Dependants",
        showInfo: false,
        filterType: "none"
      },
      {
        name: "category",
        type: "string",
        label: "Employee Category",
        showInfo: false,
        filter: this.getSelectFilter("category", this.getCategories())
      },
      {
        name: "activation_date",
        type: "date",
        label: "Coverage Start Date",
        showInfo: false
      },
      {
        name: "coverage_end_date",
        type: "date",
        label: "Coverage End Date",
        showInfo: false
      } /*,
      {
        name: "underwriting_status",
        type: "string",
        label: "Underwriting Status",
        showInfo: true
      }*/,
      {
        name: "employee_status",
        type: "string",
        label: "Account Status",
        showInfo: false,
        filter: this.getSelectFilter("employee_status", [
          { label: "invited", value: "Invited" },
          { label: "to invite", value: "Uninvited" },
          { label: "accepted", value: "Accepted" }
        ])
      }
    ];

    this._refs = {};

    this.state = {
      order: Enums.SORT.ASC,
      orderBy: "full_name",
      pageIndex: 0,
      filterValues: {
        employee_status: EMPTY_SELECT_VALUE,
        category: EMPTY_SELECT_VALUE
      },
      infoPopover: {
        open: false,
        element: null,
        data: ""
      }
    };
  }

  handleSubmitFilter = (name, value) => {
    const { policyNumber, companyId } = this.props.location.state;
    //reset all other value
    let filterValues = this.state.filterValues;
    Object.entries(filterValues).forEach(([key, value]) => {
      if (name !== key) {
        filterValues[key] = "";
        if (key === "employee_status" || key === "category") {
          filterValues[key] = EMPTY_SELECT_VALUE;
        }
      }
    });
    this.setState({ filterValues });

    let params = {
      filterBy: name,
      filterValue: value,
      sortBy: this.state.orderBy,
      dir: this.state.order
    };
    if (!value || value === "" || value === EMPTY_SELECT_VALUE) {
      params = {
        ...params,
        filterBy: "",
        filterValue: ""
      };
    }
    this.props.setSelectedEmployeeIds([]);
    this.props.getMyEmployees(policyNumber, companyId, params);
  };

  onChangePage = (event, pageIndex) => {
    const { policyNumber, companyId } = this.props.location.state;
    this.setState({ pageIndex }, () => {
      let filterName = "",
        filterValue = "";
      Object.entries(this.state.filterValues).forEach(([key, value]) => {
        if (value && value !== "" && value !== EMPTY_SELECT_VALUE) {
          filterName = key;
          filterValue = value;
          return;
        }
      });

      let params = {
        filterBy: filterName,
        filterValue: filterValue,
        sortBy: this.state.orderBy,
        dir: this.state.order,
        pageIndex
      };

      this.props.setSelectedEmployeeIds([]);
      this.props.getMyEmployees(policyNumber, companyId, params);
    });
  };

  handleFilterChange = (type, name) => event => {
    this.state.filterValues[name] = event.target.value;
    this.setState({ filterValues: this.state.filterValues }, () => {
      if (type === "select") {
        this.handleSubmitFilter(name, this.state.filterValues[name]);
      }
    });
  };

  getNoneFilter = classes => {
    return (
      <FormControl className={classes.margin}>
        <Input disabled />
      </FormControl>
    );
  };

  getTextFilter = name => classes => {
    return (
      <FormControl className={classes.margin}>
        <Input
          value={this.state.filterValues[name]}
          onChange={this.handleFilterChange("text", name)}
          classes={{
            root: this.props.isPCEClient ? classes.selectRoot : classes.textRoot,
            underline: classes.cssUnderline,
            focused: classes.cssFocused
          }}
          onKeyPress={ev => {
            if (ev.key === "Enter") {
              this.handleSubmitFilter(name, this.state.filterValues[name]);
              ev.preventDefault();
            }
          }}
          startAdornment={
            <InputAdornment position="start">
              <FilterIcon className={classes.filterIcon} />
            </InputAdornment>
          }
        />
      </FormControl>
    );
  };

  getSelectFilter = (name, data) => classes => {
    return (
      <FormControl className={classes.formControl} fullWidth>
        <Select
          value={this.state.filterValues[name]}
          onChange={this.handleFilterChange("select", name)}
          input={
            <Input
              id="select-multiple"
              classes={{
                root: classes.textRoot,
                underline: classes.cssUnderline
              }}
            />
          }
          classes={{
            root: classes.selectRoot,
            icon: classes.selectIcon
          }}
        >
          <MenuItem value={EMPTY_SELECT_VALUE}>
            <em>Select</em>
          </MenuItem>
          {data.map(d => {
            return (
              <MenuItem value={d.value}>
                <em>{d.label}</em>
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    );
  };

  getCategories = () => {
    const { coverage } = this.props;
    const product_code = this.props.location.state.productCode;
    let items = this.props.coverage.policy.data.categories;
    if ("GCP" === product_code) {
      items = this.props.coverage.policy.data.categories.filter(i => {
        const no_of_employees = i.noOfEmployees;
        return no_of_employees > 0;
      });
    }
    return items.map(cat => {
      return { label: cat.name, value: cat.name };
    });
  };

  sortHandler = col => event => {
    let order = Enums.SORT.DESC;
    if (this.state.orderBy === col && this.state.order === Enums.SORT.DESC) {
      order = Enums.SORT.ASC;
    }
    this.setState({ order, orderBy: col }, () => {
      let filterName, filterValue;
      Object.entries(this.state.filterValues).forEach(([key, value]) => {
        if (value && value !== "" && value !== EMPTY_SELECT_VALUE) {
          filterName = key;
          filterValue = value;
          return;
        }
      });
      this.handleSubmitFilter(filterName, filterValue);
    });
    //this.props.sort({ order, orderBy: col });
  };

  isSelected = id => this.props.selectedIds.indexOf(id) !== -1;
  isAllSelected = () => this.props.selectedIds.length > 0 && this.props.selectedIds.length === this.props.data.length;

  handleSelectAllClick = (event, checked) => {
    let selectedIds = [];
    const { data } = this.props;
    if (checked) {
      data.forEach((n, idx) => {
        selectedIds.push(n.person_id);
      });
    }
    this.props.setSelectedEmployeeIds(selectedIds);
  };

  handleCheck = (event, id) => {
    const { selectedIds } = this.props;
    var toAddCheck = !selectedIds.includes(id);
    var newSelected = selectedIds;
    if (toAddCheck) {
      newSelected.push(id);
    } else {
      newSelected = selectedIds.filter(s => s !== id);
    }
    newSelected = Util.uniqueList(newSelected);
    this.props.setSelectedEmployeeIds(newSelected);
  };

  renderCell(col, val) {
    const { classes } = this.props;

    if (col.name === "employee_status") {
      let icon = "",
        classNameIcon = "",
        text = "";
      if (val[col.name] === "Invited") {
        icon = "mail_outline";
        classNameIcon = "iconInvited";
        text = "invited";
      } else if (val[col.name] === "Uninvited") {
        icon = "warning";
        classNameIcon = "iconUnInvited";
        text = "to invite";
      } else if (val[col.name] === "Accepted") {
        icon = "check_circle";
        classNameIcon = "iconAccept";
        text = "accepted";
      }
      return (
        <div>
          <span className={classes.iconText}>{text}</span>
          <Icon className={classes[classNameIcon]}>{icon}</Icon>
        </div>
      );
    }

    return val[col.name];
  }

  handleClickInfoButton = (data, id) => e => {
    let infoPopover = { ...this.state.infoPopover };
    infoPopover.open = !infoPopover.open;
    if (infoPopover.open) {
      infoPopover.element = this._refs[id];
      infoPopover.data = data;
    }
    this.setState({ infoPopover });
    e.stopPropagation();
  };

  renderInfoPopover = () => {
    const { infoPopover } = this.state;
    return (
      <InfoPopover
        open={infoPopover.open}
        data={infoPopover.data}
        element={infoPopover.element}
        handleClickInfoButton={this.handleClickInfoButton}
      />
    );
  };

  getUnderwritingHelpText = () => {
    let data =
      "<ul>" +
      "<li><strong>Approved</strong> - Coverage is approved / Underwriting is not required.</li>" +
      "<li><strong>Pending </strong>- Coverage is pending underwriting. Employees below the age of 65 will be covered up to $200,000 for Group Term Life and $100,000 for Group Crisis Cover Accelerated (where applicable). Coverage for employees 65 and older will only be effected after they have been successfully underwritten.</li>" +
      "<li><strong>Limited </strong>- Coverage is limited.</li>" +
      "<li><strong>Rejected</strong> - Coverage has been declined.</li>" +
      "</ul>";
    return data;
  };

  isColumnShow = column => {
    return this.props.isPCEClient
      ? column.name === "full_name" || column.name === "id_no" || column.name === "no_of_dependent"
      : true;
  };

  handleEmulateEmployeeClick = employeeId => {
    const token = this.props.loggedUser.auth.access_token;
    const { policyNumber, companyId } = this.props.location.state;

    this.props
      .emulateEmployee(employeeId, policyNumber, companyId)
      .then(ref => {
        window.open(`//${config.employeeUrl}/emulate/${ref}/${token}`, "_blank");
      })
      .catch(error => {
        // do nothing for now
      });
  };

  render() {
    const {
      classes,
      data,
      cellClickHandler,
      products,
      myEmployee,
      isPCEClient,
      isPTFClient,
      coverage,
      loggedUser
    } = this.props;

    const { policy: { hr_claim_opt_in: claimSubmitEnabled } = {} } = coverage;
    const { auth: { pid: loggedInPersonId } = {} } = loggedUser;
    const { productCode } = this.props.location.state;
    return (
      <div className={classes.root}>
        <Paper className={classes.root}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox" className={classes.tableHeaderCell}>
                  {isPTFClient ? (
                    <Checkbox
                      style={{ fontColor: "white", color: "white" }}
                      checked={this.isAllSelected()}
                      onChange={this.handleSelectAllClick}
                    />
                  ) : (
                    <div className={classes.tableFirstCellPadding} />
                  )}
                </TableCell>

                {this.columns.map(
                  (p, idx) =>
                    this.isColumnShow(p) && (
                      <TableCell key={idx} className={classes.tableHeaderCell}>
                        <TableSortLabel
                          className={classNames(classes.tableHeaderCell, {
                            [classes.tableHeaderLabelAlign]: isPCEClient
                          })}
                          active={this.state.orderBy === p.name}
                          direction={this.state.order}
                          onClick={this.sortHandler(p.name)}
                        >
                          <div
                            className={classNames({
                              hidden: !products[productCode].config.enableDependent && p.name === "no_of_dependent"
                            })}
                          >
                            {p.label}
                          </div>
                          {p.showInfo && (
                            <Info
                              ref={el => (this._refs["cell_status"] = el)}
                              style={{ marginLeft: 5 }}
                              onClick={this.handleClickInfoButton(this.getUnderwritingHelpText(), "cell_status")}
                            />
                          )}
                        </TableSortLabel>
                        {p.filter ? p.filter(classes) : this.getNoneFilter(classes)}
                      </TableCell>
                    )
                )}

                {isPTFClient && claimSubmitEnabled && (
                  <TableCell className={classes.tableHeaderCell}>
                    <TableSortLabel
                      className={classNames(
                        classes.tableHeaderCell,
                        classes.tableHeaderLabelAlign,
                        classes.tableHeaderLabelPadding
                      )}
                    >
                      <div>Submit Claim</div>
                    </TableSortLabel>
                    {this.getNoneFilter(classes)}
                  </TableCell>
                )}

                {isPCEClient && (
                  <TableCell className={classes.tableHeaderCell}>
                    <TableSortLabel
                      className={classNames(
                        classes.tableHeaderCell,
                        classes.tableHeaderLabelAlign,
                        classes.tableHeaderLabelPadding
                      )}
                    >
                      <div>{claimSubmitEnabled ? "Submit Claim" : ""}</div>
                    </TableSortLabel>
                    {this.getNoneFilter(classes)}
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((d, id) => {
                return (
                  <TableRow key={id}>
                    <TableCell
                      padding="checkbox"
                      className={classNames(classes.tableCell, {
                        [classes.tableOldCell]: id % 2 !== 0
                      })}
                    >
                      {!isPCEClient ? (
                        <Checkbox
                          color="primary"
                          checked={this.isSelected(d.person_id)}
                          onChange={event => this.handleCheck(event, d.person_id)}
                        />
                      ) : (
                        <div className={classes.tableFirstCellPadding} />
                      )}
                    </TableCell>

                    {this.columns.map(
                      (p, idx) =>
                        this.isColumnShow(p) && (
                          <TableCell
                            key={idx}
                            onClick={() => !isPCEClient && cellClickHandler(d.person_id)()}
                            className={classNames(
                              classes.tableCell,
                              { [classes.tableOldCell]: id % 2 !== 0 },
                              {
                                [classes.tableCellPadding]: p.type === "number"
                              }
                            )}
                          >
                            <div
                              className={classNames({
                                hidden: !products[productCode].config.enableDependent && p.name === "no_of_dependent"
                              })}
                            >
                              {this.renderCell(p, d)}
                            </div>
                          </TableCell>
                        )
                    )}
                    {isPTFClient && claimSubmitEnabled && (
                      <TableCell
                        className={classNames(classes.tableCell, {
                          [classes.tableOldCell]: id % 2 !== 0
                        })}
                      >
                        {loggedInPersonId !== d.person_id ? (
                          <Button
                            variant="contained"
                            className={classes.buttonProceed}
                            onClick={() => this.handleEmulateEmployeeClick(d.person_id)}
                          >
                            Proceed
                          </Button>
                        ) : (
                          <div />
                        )}
                      </TableCell>
                    )}
                    {isPCEClient && (
                      <TableCell
                        className={classNames(classes.tableCell, {
                          [classes.tableOldCell]: id % 2 !== 0
                        })}
                      >
                        {claimSubmitEnabled && loggedInPersonId !== d.person_id ? (
                          <Button
                            variant="raised"
                            className={classes.buttonProceed}
                            onClick={() => this.handleEmulateEmployeeClick(d.person_id)}
                          >
                            Proceed
                          </Button>
                        ) : (
                          <div />
                        )}
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
            </TableBody>
            <TableFooter className={isPTFClient && claimSubmitEnabled ? "tableFooterStyle" : ""}>
              <TableRow>
                {isPCEClient || (isPTFClient && claimSubmitEnabled) ? (
                  <TablePagination
                    colSpan={5}
                    count={myEmployee.totalEmployees}
                    rowsPerPage={20}
                    page={this.state.pageIndex}
                    onPageChange={this.onChangePage}
                    rowsPerPageOptions={[]}
                    ActionsComponent={TablePaginationActions}
                    labelDisplayedRows={({ count, page }) => {
                      return `Page ${page + 1} - Total ${count === 0 ? 1 : Math.ceil(count / 20)}`;
                    }}
                  />
                ) : (
                  <TablePagination
                    colSpan={this.columns.length + 1}
                    count={myEmployee.totalEmployees}
                    rowsPerPage={20}
                    page={this.state.pageIndex}
                    onPageChange={this.onChangePage}
                    rowsPerPageOptions={[]}
                    ActionsComponent={TablePaginationActions}
                  />
                )}
              </TableRow>
            </TableFooter>
          </Table>
          {this.renderInfoPopover()}
        </Paper>
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 1,
    overflowX: "auto"
  },
  table: {
    minWidth: 900
  },
  tableHeaderCell: {
    color: "white !important",
    padding: "5px 10px 0 5px",
    backgroundColor: "black"
  },
  tableCell: {
    padding: "0 10px 0 5px"
  },
  tableCellPadding: {
    paddingLeft: 10
  },
  tableCenterCell: {
    textAlign: "center"
  },
  tableOldCell: {
    backgroundColor: "rgba(134, 133, 133, 0.1)"
  },
  tableFirstCellPadding: {
    paddingLeft: 45
  },
  tableHeaderLabelAlign: {
    verticalAlign: "top"
  },
  tableHeaderLabelPadding: {
    paddingLeft: 15
  },
  buttonProceed: {
    width: 130
  },
  iconAccept: {
    height: 22,
    fontSize: 22,
    display: "inline-block",
    marginLeft: 10,
    verticalAlign: "middle",
    color: "green"
  },
  iconUnInvited: {
    height: 22,
    fontSize: 22,
    display: "inline-block",
    marginLeft: 10,
    verticalAlign: "middle",
    color: theme.palette.primary.main
  },
  iconInvited: {
    height: 22,
    fontSize: 22,
    display: "inline-block",
    marginLeft: 10,
    verticalAlign: "middle"
  },
  iconText: {
    width: 50,
    display: "inline-block"
  },
  margin: {
    marginRight: 12
  },
  cssLabel: {
    "&$cssFocused": {
      color: "red"
    }
  },
  cssFocused: {
    color: "white !important"
  },
  cssUnderline: {
    "&:after": {
      backgroundColor: "white"
    }
  },
  textRoot: {
    fontSize: "0.75rem",
    fontWeight: 400,
    color: "white",
    backgroundColor: "#a5a5a5",
    padding: "2px"
  },
  selectIcon: {
    color: "white"
  },
  selectRoot: {
    color: "white",
    paddingLeft: 5,
    borderBottomColor: "white",
    backgroundColor: "gray"
  },
  filterIcon: {
    paddingLeft: "5px"
  }
});

function mapStateToProps(state) {
  return {
    myEmployee: state.HR.myemployee,
    products: state.products,
    coverage: state.HR.coverage,
    loggedUser: state.user
  };
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(myEmployeeActions, dispatch)
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(injectIntl(withStyles(styles, { withTheme: true })(MyEmployeeTable)))
);
