import * as actionTypes from "./types";

import userService from "../services/user.service";
import Enums from "../utils/Enums";
import Util from "../utils/Util.js";
import CiamResponseCode from "../utils/CiamResponseCode";

export const userActions = {
  refreshToken,
  login,
  CIAMLogin,
  logout,
  getRSA,
  getOtp,
  getResetPasswordOTP,
  getResetLinkValidity,
  putResetPasswordOTP,
  postOTP,
  forgotPassword,
  updateToken,
  agreeTnc,
  getHRInfo,
  getHRLoggedInfo,
  getBannerList
};

function agreeTnc(params) {
  return dispatch => {
    return userService.agreeTnc(params).then(
      resp => {
        dispatch(updateToken(resp.data));
        return Promise.resolve(resp);
      },
      error => {
        return Promise.reject(error);
      }
    );
  };
}

function getHRInfo(quote_id) {
  return dispatch => {
    return userService.getHRInfo(quote_id).then(
      response => {
        dispatch({
          type: actionTypes.HR_GET_INFO_SUCCESS,
          hrInformation: response.data
        });
        dispatch(getHRLoggedInfo());
        dispatch(getBannerList());
        return Promise.resolve(response);
      },
      error => {
        return Promise.reject(error);
      }
    );
  };
}

function getBannerList() {
  return (dispatch, getState) => {
    const state = getState();
    return userService.getBannerList(state.user.hrInformation.id).then(
      response => {
        if (response.success) {
          dispatch({
            type: actionTypes.HR_GET_BANNER_SUCCESS,
            payload: response.data
          });
        }
        return Promise.resolve(response);
      },
      error => {
        dispatch({ type: actionTypes.HR_GET_BANNER_ERROR });
        return Promise.reject(error);
      }
    );
  };
}

function getRSA(username, password) {
  return dispatch => {
    dispatch(reset());
    dispatch(request({ username }));

    return userService.getRSA().then(
      resp => {
        return Promise.resolve(resp);
      },
      error => {
        return Promise.reject(error);
      }
    );
  };

  function request(user) {
    return { type: actionTypes.USER_LOGIN_REQUEST, user };
  }

  function reset() {
    return { type: actionTypes.APP_RESET };
  }
}

function getOtp(username, password, randomKey, txnId) {
  return dispatch => {
    return userService.getOtp({ username, password, randomKey, txnId }).then(
      resp => {
        return Promise.resolve(resp);
      },
      error => {
        return Promise.reject(error);
      }
    );
  };
}

function getResetPasswordOTP(code) {
  return dispatch => {
    return userService.getResetPasswordOTP(code).then(
      resp => {
        return Promise.resolve(resp);
      },
      error => {
        return Promise.reject(error);
      }
    );
  };
}

function getResetLinkValidity(code) {
  return dispatch => {
    return userService.getResetLinkValidity(code).then(
      resp => {
        return Promise.resolve(resp);
      },
      error => {
        return Promise.reject(error);
      }
    );
  };
}

function putResetPasswordOTP(params) {
  return dispatch => {
    return userService.putResetPasswordOTP(params).then(
      resp => {
        return Promise.resolve(resp);
      },
      error => {
        return Promise.reject(error);
      }
    );
  };
}

function postOTP(otp, password, randomKey, txnId) {
  return dispatch => {
    return userService.postOTP({ otp, password, randomKey, txnId }).then(
      resp => {
        return Promise.resolve(resp);
      },
      error => {
        error["customMessage"] = Util.validateOtpLogin(error.message);
        return Promise.reject(error);
      }
    );
  };
}

function forgotPassword(username, app) {
  return dispatch => {
    return userService.forgotPassword({ username, app }).then(
      resp => {
        return Promise.resolve(resp);
      },
      error => {
        return Promise.reject(error);
      }
    );
  };
}
function refreshToken(token) {
  return {
    type: actionTypes.USER_REFRESH_TOKEN,
    payload: userService.refreshToken(token)
  };
}

function CIAMLogin(username, password, randomKey, txnId, cnonce, chash, captchaTxn, captchaCode, encryptedObj) {
  return (dispatch, getState) => {
    dispatch(reset());
    dispatch(request({ username }));

    const state = getState();
    const app = state.app;

    return userService
      .CIAMLogin({
        username: encodeURIComponent(username),
        password: encodeURIComponent(password),
        randomKey: randomKey,
        txnId: txnId,
        cnonce,
        chash,
        captchaTxn: captchaTxn,
        captchaCode: captchaCode,
        encryptedObj
      })
      .then(
        response => {
          const tokenObj = Util.jwt_decode(response.data.access_token);
          if (response.success) {
            if (
              tokenObj.ars &&
              ((app.type === Enums.APP_TYPE.SALES && tokenObj.ars.includes(Enums.ACCESSIBLE_ROLES.AGENT)) ||
                (app.type === Enums.APP_TYPE.HR && tokenObj.ars.includes(Enums.ACCESSIBLE_ROLES.HR)))
            ) {
              dispatch(success(response.data));
            } else {
              response["code"] = 400;
              response["customMessage"] = Util.validateLogin(appName, CiamResponseCode.UNAUTHORIZED);
              dispatch(failure(response));
              return Promise.reject(response);
            }
          }
          return Promise.resolve(response);
        },
        error => {
          let errorCode = error.message.split(CiamResponseCode.CIAM_KEYWORD);
          let appName = app.type === Enums.APP_TYPE.SALES;
          error["customMessage"] = Util.validateLogin(appName, errorCode[0]);
          dispatch(failure(error));
          return Promise.reject(error);
        }
      );
  };

  function request(user) {
    return { type: actionTypes.USER_LOGIN_REQUEST, user };
  }
  function success(user) {
    return { type: actionTypes.USER_LOGIN_SUCCESS, user, username };
  }
  function failure(error) {
    return { type: actionTypes.USER_LOGIN_FAILURE, error };
  }
  function reset() {
    return { type: actionTypes.APP_RESET };
  }
}

function login(username, password) {
  return dispatch => {
    dispatch(reset());
    dispatch(request({ username }));
    return new Promise((resolve, reject) => {
      userService
        .login({
          username: encodeURIComponent(username),
          password: encodeURIComponent(password)
        })
        .then(
          response => {
            if (response.success) {
              const tokenObj = Util.jwt_decode(response.data.access_token);
              if (tokenObj.ars && tokenObj.ars.includes(Enums.ACCESSIBLE_ROLES.HR)) {
                dispatch(success(response.data));
              } else {
                response["code"] = 400;
                response["customMessage"] = "The username or password you entered is incorrect.";
                dispatch(failure(response));
                return reject();
              }
            }
            return resolve(response);
          },
          error => {
            if (error.code === 400) error["customMessage"] = "The username or password you entered is incorrect.";
            dispatch(failure(error));
            reject(error);
          }
        );
    });
  };
  function request(user) {
    return { type: actionTypes.USER_LOGIN_REQUEST, user };
  }
  function success(user) {
    return { type: actionTypes.USER_LOGIN_SUCCESS, user, username };
  }
  function failure(error) {
    return { type: actionTypes.USER_LOGIN_FAILURE, error };
  }
  function reset() {
    return { type: actionTypes.APP_RESET };
  }
}

function logout() {
  userService.logout().catch(err => {
    console.log(err.defaultMessage);
  });
  return { type: actionTypes.USER_LOGOUT };
}

function updateToken(data) {
  return { type: actionTypes.USER_UPDATE_TOKEN, payload: data };
}

function getHRLoggedInfo() {
  return (dispatch, getState) => {
    let personId = getState().user.auth.pid;
    return userService.getHRLoggedInfo(personId).then(
      response => {
        dispatch({
          type: actionTypes.HR_GET_USER_INFO_SUCCESS,
          payload: response.data
        });
        return Promise.resolve(response);
      },
      error => {
        return Promise.reject(error);
      }
    );
  };
}
