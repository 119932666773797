import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStyles } from "@mui/styles";
import { injectIntl } from "react-intl";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepButton from "@mui/material/StepButton";
import Typography from "@mui/material/Typography";
import SelectPlans from "./containers/SelectPlans";
import EnrollEmployee from "./containers/EnrollEmployee";
import CompanyDetail from "./containers/CompanyDetail";
import SubmitDoc from "./containers/SubmitDoc";
import ActionFooter from "./components/ActionFooter";
import { employeeActions, quoteActions, appActions } from "../../../../actions";
import CustomBreadcrumb from "../../../../components/CustomBreadcrumb";

import ENUMS from "../../../../utils/Enums";
import Util from "../../../../utils/Util";

class Quote extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeStep: this.props.quote.activeStep,
      completed: {},
      hasEdits: false,
      validFiles: false,
      validPayMethod: false,
      validUen: false
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.document && this.validateFiles(nextProps.document.files)) {
      this.handleSubmitValidation(0, true);
    } else {
      this.handleSubmitValidation(0, false);
    }
    if (
      nextProps.quote.payment_method === ENUMS.PAYMENT_METHOD.CREDIT_CARD &&
      nextProps.quote.payment_status === ENUMS.PAYMENT_STATUS.DONE
    ) {
      this.handleSubmitValidation(1, true);
    } else if (nextProps.quote.payment_method === ENUMS.PAYMENT_METHOD.BANK_TRANSFER) {
      this.handleSubmitValidation(1, true);
    } else if (nextProps.quote.payment_method === ENUMS.PAYMENT_METHOD.CHEQUE) {
      this.handleSubmitValidation(1, true);
    } else {
      this.handleSubmitValidation(1, false);
    }

    if (nextProps.document && Util.validateUen(nextProps?.company?.details?.uen_no)) {
      this.handleSubmitValidation(2, true);
    } else {
      this.handleSubmitValidation(2, false);
    }
  }

  validateFiles = files => {
    if (Util.isEmpty(files)) return false;

    let valid = {};
    valid[ENUMS.FILE_TYPE.ACCEPTANCE_FILE_TYPE] = false;
    valid[ENUMS.FILE_TYPE.ACRA_FILE_TYPE] = false;
    valid[ENUMS.FILE_TYPE.MAS314_FILE_TYPE] = false;
    valid[ENUMS.FILE_TYPE.CORP_BANK_DETAILS] = false;
    valid[ENUMS.FILE_TYPE.NRIC_IND_FILE_TYPE] = false;
    valid[ENUMS.FILE_TYPE.DECLARATION_CLAIM_ENTITLEMENT_FILE_TYPE] = false;
    files.forEach(file => {
      valid[file.file_type] = true;
    });
    return (
      valid[ENUMS.FILE_TYPE.ACCEPTANCE_FILE_TYPE] &&
      valid[ENUMS.FILE_TYPE.ACRA_FILE_TYPE] &&
      valid[ENUMS.FILE_TYPE.MAS314_FILE_TYPE] &&
      valid[ENUMS.FILE_TYPE.CORP_BANK_DETAILS] &&
      valid[ENUMS.FILE_TYPE.NRIC_IND_FILE_TYPE] &&
      valid[ENUMS.FILE_TYPE.DECLARATION_CLAIM_ENTITLEMENT_FILE_TYPE]
    );
  };

  handleHasEdits = () => {
    this.setState({ hasEdits: true });
  };

  handleResetEdits = () => {
    this.setState({ hasEdits: false });
  };

  handleSubmitValidation = (comp, valid) => {
    const component = ["validFiles", "validPayMethod", "validUen"];

    if (this.state[component[comp]] === valid) return;

    let obj = {};
    obj[component[comp]] = valid;
    this.setState(obj);
  };

  finishAutoSave = () => {
    this.setState({ hasEdits: false });
  };

  render() {
    const { classes, quote, navDrawerOpen } = this.props;
    const { activeStep } = quote;
    const steps = ["Select Plans", "Add Employees Info", "Add Company Info", "Submit Documents"];
    let stepContent;

    if (activeStep === 0) {
      stepContent = <SelectPlans handleHasEdits={this.handleHasEdits} />;
    } else if (activeStep === 1) {
      stepContent = <EnrollEmployee handleHasEdits={this.handleHasEdits} />;
    } else if (activeStep === 2) {
      stepContent = <CompanyDetail handleHasEdits={this.handleHasEdits} handleResetEdits={this.handleResetEdits} />;
    } else if (activeStep === 3) {
      stepContent = <SubmitDoc />;
    } else {
      stepContent = <Typography>{steps[activeStep]}</Typography>;
    }

    return (
      <div className={classes.root}>
        <div className={classes.container}>
          <Stepper alternativeLabel nonLinear activeStep={activeStep} className={classes.stepper}>
            {steps.map((label, index) => {
              return (
                <Step
                  key={label}
                  classes={{
                    root: classes.stepContainer
                  }}
                >
                  <StepButton
                    onClick={this.handleStep(index)}
                    completed={this.state.completed[index]}
                    className={classes.stepButton}
                  >
                    <StepLabel
                      classes={{
                        label: classes.stepLabel
                      }}
                    >
                      {label}
                    </StepLabel>
                  </StepButton>
                </Step>
              );
            })}
          </Stepper>
          <div className={classes.breadcrumb}>
            <CustomBreadcrumb currentPage="CurrentQuote" />
          </div>
          <div className={classes.stepContent}>{stepContent}</div>
        </div>

        <ActionFooter
          activeStep={activeStep}
          hasEdits={this.state.hasEdits}
          handlerNextStep={this.handleStep.bind(this)}
          navDrawerOpen={navDrawerOpen}
          valid={this.state.validFiles && this.state.validPayMethod && this.state.validUen}
        />
      </div>
    );
  }

  autoSaveQuote = (prev, step) => {
    let recalculate = false;

    switch (prev) {
      case 0:
        prev = ENUMS.STAGES.ESTIMATE;
        break;
      case 1:
        prev = ENUMS.STAGES.EMPLOYEE;
        break;
      case 2:
        prev =
          this.state.hasEdits && this.props.quote.stages === ENUMS.STAGES.PROPOSAL
            ? ENUMS.STAGES.EMPLOYEE
            : this.props.quote.stages;
        break;
      default:
        prev = this.props.quote.stages;
        recalculate = true;
    }
    //clear flag if not on employee list
    if (this.props.quote.stages !== ENUMS.STAGES.SUBMITTED && this.props.quote.stages !== ENUMS.STAGES.INFORCE) {
      this.props.employeeFinishRevalidate();
    }
    if (!Util.isEmpty(prev) && (this.state.hasEdits || recalculate)) {
      this.props.save(prev).then(() => {
        this.finishAutoSave();
      });
    }

    this.props.setActiveStep(step);
  };

  handleStep = step => () => {
    this.props.scrollToTop();
    if (this.props.quote.stages !== ENUMS.STAGES.SUBMITTED && this.props.quote.stages !== ENUMS.STAGES.INFORCE) {
      this.props.validateEmployeeData(step);
    }

    if (this.props.form["company-detail"]) {
      if (this.props.form["company-detail"].syncErrors) return;
    }

    /*   if (
      this.props.quote.business_type === ENUMS.BUSINESS_TYPE.CONVERSION &&
      Util.isEmpty(this.props.quote.existing_policy_no)
    ) {
      return;
    } */

    this.autoSaveQuote(this.props.quote.activeStep, step);
  };
}

const styles = theme => ({
  root: {
    display: "flex",
    flexDirection: "Column"
  },
  container: {
    marginBottom: `${theme.actionFooter.height}px`
  },
  stepContainer: {
    display: "flex",
    alignItems: "center",
    top: 12
  },
  sectionHeader: {
    backgroundColor: theme.colors.blackScale.black50,
    width: "100%",
    alignItems: "center"
  },
  stepper: {
    backgroundColor: theme.colors.blackScale.black50,
    padding: 0,
    marginLeft: -20
  },
  stepContent: {
    padding: theme.spacing.unit * 2.5,
    paddingTop: 10
  },
  stepLabel: {
    marginTop: "3px",
    fontSize: "0.75em"
  },
  stepButton: {
    top: -12,
    margin: "0",
    padding: "13px 0"
  },
  breadcrumb: {
    margin: "10px 10px",
    paddingBottom: "20px"
  }
});

function mapStateToProps(state) {
  return {
    readOnly: state.quote.readOnly,
    quote: state.quote,
    employee: state.quote.employee,
    document: state.document,
    form: state.form,
    user: state.user,
    company: state.company
  };
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(quoteActions, dispatch),
    ...bindActionCreators(appActions, dispatch),
    ...bindActionCreators(employeeActions, dispatch)
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(Quote)));
