export const APP_RESET = "APP_RESET";
export const APP_SET_ERROR = "APP_SET_ERROR";
export const APP_500_ERROR = "APP_500_ERROR";
export const APP_SET_LOCALE = "APP_SET_LOCALE";
export const APP_SET_TYPE = "APP_SET_TYPE";
export const APP_SCROLL = "APP_SCROLL";
export const APP_SCROLL_FIN = "APP_SCROLL_FIN";

export const APP_FIRSTPAGEDIALOG_SET_VISIBILITY = "APP_FIRSTPAGEDIALOG_SET_VISIBILITY";
export const APP_RENEWALNOTIFICATION_SET_VISIBILITY = "APP_RENEWALNOTIFICATION_SET_VISIBILITY";
export const APP_SET_LOADING_STATE = "APP_SET_LOADING_STATE";
export const UPDATE_IS_RENEWAL_NOTIFICATION_OPEN = "UPDATE_IS_RENEWAL_NOTIFICATION_OPEN";

export const USER_UPDATE_TOKEN = "USER_UPDATE_TOKEN";
export const USER_REFRESH_TOKEN = "USER_REFRESH_TOKEN";
export const USER_REFRESH_TOKEN_PENDING = "USER_REFRESH_TOKEN_PENDING";
export const USER_REFRESH_TOKEN_FULFILLED = "USER_REFRESH_TOKEN_FULFILLED";
export const USER_REFRESH_TOKEN_REJECTED = "USER_REFRESH_TOKEN_REJECTED";
export const USER_SET_AUTH = "USER_SET_AUTH";
export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAILURE = "USER_LOGIN_FAILURE";
export const USER_LOGOUT = "USER_LOGOUT";

export const EMPLOYEES_LOAD_SUCCESS = "EMPLOYEES_LOAD_SUCCESS";
export const EMPLOYEES_LOAD_FAILURE = "EMPLOYEES_LOAD_FAILURE";
export const EMPLOYEES_SET = "EMPLOYEES_SET";
export const EMPLOYEE_UPLOAD_REQUEST = "EMPLOYEE_UPLOAD_REQUEST";
export const EMPLOYEE_UPLOAD_SUCCESS = "EMPLOYEE_UPLOAD_SUCCESS";
export const EMPLOYEE_UPLOAD_ERROR = "EMPLOYEE_UPLOAD_ERROR";
export const EMPLOYEES_SET_SELECTED = "EMPLOYEES_SET_SELECTED";
export const EMPLOYEE_VALIDATE_DATA = "EMPLOYEE_VALIDATE_DATA";
export const EMPLOYEE_REVALIDATED_FLAG = "EMPLOYEE_REVALIDATED_FLAG";

export const EMPLOYEE_SAVE_REQUEST = "EMPLOYEE_SAVE_REQUEST";
export const EMPLOYEE_SAVE_SUCCESS = "EMPLOYEE_SAVE_SUCCESS";
export const EMPLOYEE_SAVE_ERROR = "EMPLOYEE_SAVE_ERROR";

export const EMPLOYEE_ADD_REQUEST = "EMPLOYEE_ADD_REQUEST";

export const EMPLOYEE_DELETE_REQUEST = "EMPLOYEE_DELETE_REQUEST";
export const EMPLOYEE_DELETE_SUCCESS = "EMPLOYEE_DELETE_SUCCESS";
export const EMPLOYEE_DELETE_ERROR = "EMPLOYEE_DELETE_ERROR";

export const EMPLOYEE_MOVE_REQUEST = "EMPLOYEE_MOVE_REQUEST";
export const EMPLOYEE_MOVE_SUCCESS = "EMPLOYEE_MOVE_SUCCESS";
export const EMPLOYEE_MOVE_ERROR = "EMPLOYEE_MOVE_ERROR";
export const EMPLOYEE_LIST_SCREEN = "EMPLOYEE_LIST_SCREEN";

export const EMPLOYEE_SET_DEPENDENTS = "EMPLOYEE_SET_DEPENDENTS";
export const EMPLOYEE_SET_ERRORS = "EMPLOYEE_SET_ERRORS";

export const PRODUCT_CONFIG_LOAD_SUCCESS = "PRODUCT_CONFIG_LOAD_SUCCESS";
export const PRODUCT_CONFIG_LOAD_FAILURE = "PRODUCT_CONFIG_LOAD_FAILURE";
export const PRODUCT_CONFIG_SET_PLANS = "PRODUCT_CONFIG_SET_PLANS";
export const PRODUCT_CONFIG_SET_CATEGORIES = "PRODUCT_CONFIG_SET_CATEGORIES";
export const PRODUCT_CONFIG_LATEST_VERSION = "PRODUCT_CONFIG_LATEST_VERSION";

export const PRODUCT_ENGINE_LOAD_SUCCESS = "PRODUCT_ENGINE_LOAD_SUCCESS";
export const PRODUCT_ENGINE_LOAD_FAILURE = "PRODUCT_ENGINE_LOAD_FAILURE";

export const QUOTE_INIT = "QUOTE_INIT";
export const QUOTE_RESET_TRIGGERED = "QUOTE_RESET_TRIGGERED";
export const QUOTE_RESET = "QUOTE_RESET";
export const QUOTE_SET_PRODUCT_CODE_VERSION = "QUOTE_SET_PRODUCT_CODE_VERSION";

export const QUOTE_SET_ID = "QUOTE_SET_ID";
export const QUOTE_SET_PLANS = "QUOTE_SET_PLANS";
export const QUOTE_SET_ESTIMATED_PREMIUM = "QUOTE_SET_ESTIMATED_PREMIUM";
export const QUOTE_SET_ACTUAL_PREMIUM = "QUOTE_SET_ACTUAL_PREMIUM";
export const QUOTE_SET_CATEGORIES = "QUOTE_SET_CATEGORIES";
export const QUOTE_SET_NAME = "QUOTE_SET_NAME";
export const QUOTE_SET_EXISTING_POLICY_NO = "QUOTE_SET_EXISTING_POLICY_NO";
export const QUOTE_SET_BUSINESS_TYPE = "QUOTE_SET_BUSINESS_TYPE";

export const QUOTE_SET_SPECIFIC_INDIVIDUALS = "QUOTE_SET_SPECIFIC_INDIVIDUALS";

export const QUOTE_SET_AML_CLAUSE_ACTION = "QUOTE_SET_AML_CLAUSE_ACTION";

export const QUOTE_SET_ESTIMATES = "QUOTE_SET_ESTIMATES";
export const QUOTE_SET_PERSONS = "QUOTE_SET_PERSONS";
export const QUOTE_SET_ACTIVE_STEP = "QUOTE_SET_ACTIVE_STEP";
export const QUOTE_SET_PDPA = "QUOTE_SET_PDPA";

export const QUOTE_SAVE = "QUOTE_SAVE";
export const QUOTE_SAVE_FULFILLED = "QUOTE_SAVE_FULFILLED";
export const QUOTE_SAVE_REJECTED = "QUOTE_SAVE_REJECTED";

export const QUOTE_DELETE = "QUOTE_DELETE";

export const QUOTE_GET = "QUOTE_GET";
export const QUOTE_GET_PENDING = "QUOTE_GET_PENDING";
export const QUOTE_GET_FULFILLED = "QUOTE_GET_FULFILLED";
export const QUOTE_GET_REJECTED = "QUOTE_GET_REJECTED";

export const QUOTES_GET_LIST = "QUOTES_GET_LIST";
export const QUOTES_GET_LIST_PENDING = "QUOTES_GET_LIST_PENDING";
export const QUOTES_GET_LIST_FULFILLED = "QUOTES_GET_LIST_FULFILLED";
export const QUOTES_GET_LIST_REJECTED = "QUOTES_GET_LIST_REJECTED";
export const QUOTE_SET_ACTIVATION_DATE = "QUOTE_SET_ACTIVATION_DATE";
export const QUOTE_SET_CONFIRM_ACTIVATION_DATE = "QUOTE_SET_CONFIRM_ACTIVATION_DATE";
export const QUOTE_SET_WAITING_PERIOD = "QUOTE_SET_WAITING_PERIOD";
export const QUOTE_SET_STAGE_VALIDATION = "QUOTE_SET_STAGE_VALIDATION";
export const QUOTE_SET_REVALIDATE_FLAG = "QUOTE_SET_REVALIDATE_FLAG";
export const QUOTE_UNSET_REVALIDATE_FLAG = "QUOTE_UNSET_REVALIDATE_FLAG";
export const QUOTE_SET_DEPENDANT_SNACKBAR_FLAG = "QUOTE_SET_DEPENDANT_SNACKBAR_FLAG";

export const QUOTE_SUBMIT_POLICY = "QUOTE_SUBMIT_POLICY";
export const QUOTE_SUBMIT_POLICY_SUCCESS = "QUOTE_SUBMIT_POLICY_SUCCESS";
export const QUOTE_SUBMIT_POLICY_ERROR = "QUOTE_SUBMIT_POLICY_ERROR";
export const QUOTE_SUBMIT_POLICY_CLOSE_SUCCESS_POPUP = "QUOTE_SUBMIT_POLICY_CLOSE_SUCCESS_POPUP";
export const QUOTE_SUBMIT_POLICY_CLOSE_SNACKBAR = "QUOTE_SUBMIT_POLICY_CLOSE_SNACKBAR";

export const QUOTE_SUBMIT_QUOTE = "QUOTE_SUBMIT_QUOTE";
export const QUOTE_SUBMIT_QUOTE_SUCCESS = "QUOTE_SUBMIT_QUOTE_SUCCESS";
export const QUOTE_SUBMIT_QUOTE_ERROR = "QUOTE_SUBMIT_QUOTE_ERROR";

export const QUOTE_SET_PAYMENT_METHOD = "QUOTE_SET_PAYMENT_METHOD";
export const QUOTE_MAKE_PAYMENT = "QUOTE_MAKE_PAYMENT";
export const QUOTE_MAKE_PAYMENT_PENDING = "QUOTE_MAKE_PAYMENT_PENDING";
export const QUOTE_MAKE_PAYMENT_FULFILLED = "QUOTE_MAKE_PAYMENT_FULFILLED";
export const QUOTE_MAKE_PAYMENT_REJECTED = "QUOTE_MAKE_PAYMENT_REJECTED";

export const QUOTE_GET_KOFAX = "QUOTE_GET_KOFAX";
export const QUOTE_E_SIGN_CONTENT_ERROR = "QUOTE_E_SIGN_CONTENT_ERROR";
export const QUOTE_GET_DOCUMENT_SIGN_STATUS = "QUOTE_GET_DOCUMENT_SIGN_STATUS";
export const QUOTE_GET_DOCUMENT_SIGN_STATUS_FULFILLED = "QUOTE_GET_DOCUMENT_SIGN_STATUS_FULFILLED";
export const QUOTE_GET_DOCUMENT_SIGN_STATUS_ERROR = "QUOTE_GET_DOCUMENT_SIGN_STATUS_ERROR";
export const QUOTE_GET_DOCUMENT_CALLBACK_STATUS = "QUOTE_GET_DOCUMENT_CALLBACK_STATUS";
export const QUOTE_GET_DOCUMENT_CALLBACK_STATUS_FULFILLED = "QUOTE_GET_DOCUMENT_CALLBACK_STATUS_FULFILLED";
export const QUOTE_GET_DOCUMENT_CALLBACK_STATUS_ERROR = "QUOTE_GET_DOCUMENT_CALLBACK_STATUS_ERROR";
export const QUOTE_GET_SIGNED_DOCUMENT = "QUOTE_GET_SIGNED_DOCUMENT";

export const QUOTE_GET_SIGNED_DOCUMENT_PENDING = "QUOTE_GET_SIGNED_DOCUMENT_PENDING";
export const QUOTE_GET_SIGNED_DOCUMENT_FULFILLED = "QUOTE_GET_SIGNED_DOCUMENT_FULFILLED";
export const QUOTE_GET_SIGNED_DOCUMENT_ERROR = "QUOTE_GET_SIGNED_DOCUMENT_ERROR";

export const QUOTE_EXISTING_POLICY = "QUOTE_EXISTING_POLICY";
export const QUOTE_EXISTING_POLICY_PENDING = "QUOTE_EXISTING_POLICY_PENDING";
export const QUOTE_EXISTING_POLICY_FULFILLED = "QUOTE_EXISTING_POLICY_FULFILLED";
export const QUOTE_EXISTING_POLICY_REJECTED = "QUOTE_EXISTING_POLICY_REJECTED";

export const QUOTE_EXISTING_POLICY_MEMBER_DETAILS = "QUOTE_EXISTING_POLICY_MEMBER_DETAILS";
export const QUOTE_EXISTING_POLICY_MEMBER_DETAILS_PENDING = "QUOTE_EXISTING_POLICY_MEMBER_DETAILS_PENDING";
export const QUOTE_EXISTING_POLICY_MEMBER_DETAILS_FULFILLED = "QUOTE_EXISTING_POLICY_MEMBER_DETAILS_FULFILLED";
export const QUOTE_EXISTING_POLICY_MEMBER_DETAILS_REJECTED = "QUOTE_EXISTING_POLICY_MEMBER_DETAILS_REJECTED";

export const COMPANY_SET_FORM_DETAILS = "COMPANY_SET_FORM_DETAILS";

export const COMPANY_DETAIL_RESET = "COMPANY_DETAIL_RESET";
export const COMPANY_CACHE_DETAIL_RESET = "COMPANY_CACHE_DETAIL_RESET";
export const SET_CACHE_TO_COMPANY_DETAIL = "SET_CACHE_TO_COMPANY_DETAIL";
export const COMPANY_DETAIL_GET = "COMPANY_DETAIL_GET";
export const COMPANY_DETAIL_GET_PENDING = "COMPANY_DETAIL_GET_PENDING";
export const COMPANY_DETAIL_GET_FULFILLED = "COMPANY_DETAIL_GET_FULFILLED";
export const COMPANY_DETAIL_GET_REJECTED = "COMPANY_DETAIL_GET_REJECTED";

export const COMPANY_DETAIL_PUT = "COMPANY_DETAIL_PUT";
export const COMPANY_DETAIL_PUT_PENDING = "COMPANY_DETAIL_PUT_PENDING";
export const COMPANY_DETAIL_PUT_FULFILLED = "COMPANY_DETAIL_PUT_FULFILLED";
export const COMPANY_DETAIL_PUT_REJECTED = "COMPANY_DETAIL_PUT_REJECTED";

export const COMPANY_DETAIL_POST = "COMPANY_DETAIL_POST";
export const COMPANY_DETAIL_POST_PENDING = "COMPANY_DETAIL_POST_PENDING";
export const COMPANY_DETAIL_POST_FULFILLED = "COMPANY_DETAIL_POST_FULFILLED";
export const COMPANY_DETAIL_POST_REJECTED = "COMPANY_DETAIL_POST_REJECTED";

export const COMPANY_DETAIL_SAVE_REQUEST = "COMPANY_DETAIL_SAVE_REQUEST";
export const COMPANY_DETAIL_SAVE_SUCCESS = "COMPANY_DETAIL_SAVE_SUCCESS";
export const COMPANY_DETAIL_SAVE_ERROR = "COMPANY_DETAIL_SAVE_ERROR";

export const CONFIG_SET_LOCALE = "CONFIG_SET_LOCALE";

export const RENEWAL_DOCUMENT_ALL_GET = "RENEWAL_DOCUMENT_ALL_GET";
export const RENEWAL_DOCUMENT_ALL_GET_PENDING = "RENEWAL_DOCUMENT_ALL_GET_PENDING";
export const RENEWAL_DOCUMENT_ALL_GET_FULFILLED = "RENEWAL_DOCUMENT_ALL_GET_FULFILLED";

export const RENEWAL_DOCUMENT_DELETE = "RENEWAL_DOCUMENT_DELETE";
export const RENEWAL_DOCUMENT_DELETE_PENDING = "RENEWAL_DOCUMENT_DELETE_PENDING";
export const RENEWAL_DOCUMENT_DELETE_FULFILLED = "RENEWAL_DOCUMENT_DELETE_FULFILLED";
export const RENEWAL_DOCUMENT_DELETE_REJECTED = "RENEWAL_DOCUMENT_DELETE_REJECTED";

export const LETTER_UPLOAD = "LETTER_UPLOAD";
export const LETTER_UPLOAD_PENDING = "LETTER_UPLOAD_PENDING";
export const LETTER_UPLOAD_FULFILLED = "LETTER_UPLOAD_FULFILLED";
export const LETTER_UPLOAD_REJECTED = "LETTER_UPLOAD_REJECTED";

export const HR_DEC_UPLOAD = "HR_DEC_UPLOAD";
export const HR_DEC_UPLOAD_PENDING = "HR_DEC_UPLOAD_PENDING";
export const HR_DEC_UPLOAD_FULFILLED = "HR_DEC_UPLOAD_FULFILLED";
export const HR_DEC_UPLOAD_REJECTED = "HR_DEC_UPLOAD_REJECTED";

export const DEC_ENTITLEMENT_UPLOAD = "DEC_ENTITLEMENT_UPLOAD";
export const DEC_ENTITLEMENT_UPLOAD_PENDING = "DEC_ENTITLEMENT_UPLOAD_PENDING";
export const DEC_ENTITLEMENT_UPLOAD_FULFILLED = "DEC_ENTITLEMENT_UPLOAD_FULFILLED";
export const DEC_ENTITLEMENT_UPLOAD_REJECTED = "DEC_ENTITLEMENT_UPLOAD_REJECTED";

export const DEC_SPECIFIC_UPLOAD = "DEC_SPECIFIC_UPLOAD";
export const DEC_SPECIFIC_UPLOAD_PENDING = "DEC_SPECIFIC_UPLOAD_PENDING";
export const DEC_SPECIFIC_UPLOAD_FULFILLED = "DEC_SPECIFIC_UPLOAD_FULFILLED";
export const DEC_SPECIFIC_UPLOAD_REJECTED = "DEC_SPECIFIC_UPLOAD_REJECTED";

export const SET_RENEW_HAS_HR_DEC_CHANGED = "SET_RENEW_HAS_HR_DEC_CHANGED";

export const MEMBER_UPDATE_UPLOAD = "MEMBER_UPDATE_UPLOAD";
export const MEMBER_UPDATE_UPLOAD_PENDING = "MEMBER_UPDATE_UPLOAD_PENDING";
export const MEMBER_UPDATE_UPLOAD_FULFILLED = "MEMBER_UPDATE_UPLOAD_FULFILLED";
export const MEMBER_UPDATE_UPLOAD_REJECTED = "MEMBER_UPDATE_UPLOAD_REJECTED";

export const SET_RENEW_ID = "SET_RENEW_ID";
export const SET_RENEW_STATUS = "SET_RENEW_STATUS";

export const SIGNED_QUO_UPLOAD = "SIGNED_QUO_UPLOAD";
export const SIGNED_QUO_UPLOAD_PENDING = "SIGNED_QUO_UPLOAD_PENDING";
export const SIGNED_QUO_UPLOAD_FULFILLED = "SIGNED_QUO_UPLOAD_FULFILLED";
export const SIGNED_QUO_UPLOAD_REJECTED = "SIGNED_QUO_UPLOAD_REJECTED";

export const AUTHO_SIGNATORY_UPLOAD = "AUTHO_SIGNATORY_UPLOAD";
export const AUTHO_SIGNATORY_UPLOAD_PENDING = "AUTHO_SIGNATORY_UPLOAD_PENDING";
export const AUTHO_SIGNATORY_UPLOAD_FULFILLED = "AUTHO_SIGNATORY_UPLOAD_FULFILLED";
export const AUTHO_SIGNATORY_UPLOAD_REJECTED = "AUTHO_SIGNATORY_UPLOAD_REJECTED";

export const HEADCOUNT_UPLOAD = "HEADCOUNT_UPLOAD";
export const HEADCOUNT_UPLOAD_PENDING = "HEADCOUNT_UPLOAD_PENDING";
export const HEADCOUNT_UPLOAD_FULFILLED = "HEADCOUNT_UPLOAD_FULFILLED";
export const HEADCOUNT_UPLOAD_REJECTED = "HEADCOUNT_UPLOAD_REJECTED";

export const ACRA_UPLOAD = "ACRA_UPLOAD";
export const ACRA_UPLOAD_PENDING = "ACRA_UPLOAD_PENDING";
export const ACRA_UPLOAD_FULFILLED = "ACRA_UPLOAD_FULFILLED";
export const ACRA_UPLOAD_REJECTED = "ACRA_UPLOAD_REJECTED";

export const MAS314_UPLOAD = "MAS314_UPLOAD";
export const MAS314_UPLOAD_PENDING = "MAS314_UPLOAD_PENDING";
export const MAS314_UPLOAD_FULFILLED = "MAS314_UPLOAD_FULFILLED";
export const MAS314_UPLOAD_REJECTED = "MAS314_UPLOAD_REJECTED";

export const DOCUMENT_ACCEPTANCE_LETTER_UPLOAD = "DOCUMENT_ACCEPTANCE_LETTER_UPLOAD";
export const DOCUMENT_ACCEPTANCE_LETTER_UPLOAD_PENDING = "DOCUMENT_ACCEPTANCE_LETTER_UPLOAD_PENDING";
export const DOCUMENT_ACCEPTANCE_LETTER_UPLOAD_FULFILLED = "DOCUMENT_ACCEPTANCE_LETTER_UPLOAD_FULFILLED";
export const DOCUMENT_ACCEPTANCE_LETTER_UPLOAD_REJECTED = "DOCUMENT_ACCEPTANCE_LETTER_UPLOAD_REJECTED";

export const DOCUMENT_ACRA_UPLOAD = "DOCUMENT_ACRA_UPLOAD";
export const DOCUMENT_ACRA_UPLOAD_PENDING = "DOCUMENT_ACRA_UPLOAD_PENDING";
export const DOCUMENT_ACRA_UPLOAD_FULFILLED = "DOCUMENT_ACRA_UPLOAD_FULFILLED";
export const DOCUMENT_ACRA_UPLOAD_REJECTED = "DOCUMENT_ACRA_UPLOAD_REJECTED";

export const DOCUMENT_MAS314_UPLOAD = "DOCUMENT_MAS314_UPLOAD";
export const DOCUMENT_MAS314_UPLOAD_PENDING = "DOCUMENT_MAS314_UPLOAD_PENDING";
export const DOCUMENT_MAS314_UPLOAD_FULFILLED = "DOCUMENT_MAS314_UPLOAD_FULFILLED";
export const DOCUMENT_MAS314_UPLOAD_REJECTED = "DOCUMENT_MAS314_UPLOAD_REJECTED";

export const DOCUMENT_CORP_BANK_UPLOAD = "DOCUMENT_CORP_BANK_UPLOAD";
export const DOCUMENT_CORP_BANK_UPLOAD_PENDING = "DOCUMENT_CORP_BANK_UPLOAD_PENDING";
export const DOCUMENT_CORP_BANK_UPLOAD_FULFILLED = "DOCUMENT_CORP_BANK_UPLOAD_FULFILLED";
export const DOCUMENT_CORP_BANK_UPLOAD_REJECTED = "DOCUMENT_CORP_BANK_UPLOAD_REJECTED";

//Declaration NRIC_IND
export const DOCUMENT_NRIC_IND_UPLOAD = "DOCUMENT_NRIC_IND_UPLOAD";
export const DOCUMENT_NRIC_IND_UPLOAD_PENDING = "DOCUMENT_NRIC_IND_UPLOAD_PENDING";
export const DOCUMENT_NRIC_IND_UPLOAD_FULFILLED = "DOCUMENT_NRIC_IND_UPLOAD_FULFILLED";
export const DOCUMENT_NRIC_IND_UPLOAD_REJECTED = "DOCUMENT_NRIC_IND_UPLOAD_REJECTED";

//Declaration Entitlement of Tax
export const DOCUMENT_DECLARATION_CLAIM_ENTITLEMENT_UPLOAD = "DOCUMENT_DECLARATION_CLAIM_ENTITLEMENT_UPLOAD";
export const DOCUMENT_DECLARATION_CLAIM_ENTITLEMENT_UPLOAD_PENDING =
  "DOCUMENT_DECLARATION_CLAIM_ENTITLEMENT_UPLOAD_PENDING";
export const DOCUMENT_DECLARATION_CLAIM_ENTITLEMENT_UPLOAD_FULFILLED =
  "DOCUMENT_DECLARATION_CLAIM_ENTITLEMENT_UPLOAD_FULFILLED";
export const DOCUMENT_DECLARATION_CLAIM_ENTITLEMENT_UPLOAD_REJECTED =
  "DOCUMENT_DECLARATION_CLAIM_ENTITLEMENT_UPLOAD_REJECTED";

export const DOCUMENT_ALL_GET = "DOCUMENT_ALL_GET";
export const DOCUMENT_ALL_GET_PENDING = "DOCUMENT_ALL_GET_PENDING";
export const DOCUMENT_ALL_GET_FULFILLED = "DOCUMENT_ALL_GET_FULFILLED";
export const DOCUMENT_ALL_GET_REJECTED = "DOCUMENT_ALL_GET_REJECTED";

export const DOCUMENT_RESET = "DOCUMENT_RESET";
export const DOCUMENT_DELETE = "DOCUMENT_DELETE";
export const DOCUMENT_DELETE_PENDING = "DOCUMENT_DELETE_PENDING";
export const DOCUMENT_DELETE_FULFILLED = "DOCUMENT_DELETE_FULFILLED";
export const DOCUMENT_DELETE_REJECTED = "DOCUMENT_DELETE_REJECTED";

export const BENEFIT_SELECT = "BENEFIT_SELECT";

export const POLICY_GET_ALL_POLICIES = "POLICY_GET_ALL_POLICIES";
export const POLICY_DETAILS_SET_CURRENT_POLICY_NO = "POLICY_DETAILS_SET_CURRENT_POLICY_NO";
export const POLICY_DETAILS_GET_POLICY = "POLICY_DETAILS_GET_POLICY";
export const POLICY_DETAILS_REMOVE_POLICY = "POLICY_DETAILS_REMOVE_POLICY";
export const POLICY_GET_MY_EMPLOYEES = "POLICY_GET_MY_EMPLOYEES";
export const POLICY_MY_EMPLOYEE_LOAD_CATEGORY = "POLICY_MY_EMPLOYEE_LOAD_CATEGORY";
export const POLICY_MY_EMPLOYEE_ALL_DEPENDENT = "POLICY_MY_EMPLOYEE_ALL_DEPENDENT";
export const POLICY_GET_ALL_RELATED_DOCUMENTS = "POLICY_GET_ALL_RELATED_DOCUMENTS";

export const POLICY_CLAIM_EXPENSE_SHOW_LOADING = "POLICY_CLAIM_EXPENSE_SHOW_LOADING";
export const POLICY_CLAIM_EXPENSE_CLOSE_LOADING = "POLICY_CLAIM_EXPENSE_CLOSE_LOADING";
export const POLICY_CLAIM_EXPENSE_SHOW_POPUP_ERROR = "HPOLICY_CLAIM_EXPENSE_SHOW_POPUP_ERROR";
export const POLICY_CLAIM_EXPENSE_SHOW_POPUP_SUCCESS = "POLICY_CLAIM_EXPENSE_SHOW_POPUP_SUCCESS;";
export const POLICY_CLAIM_EXPENSE_CLOSE_POPUP = "POLICY_CLAIM_EXPENSE_CLOSE_POPUP";
export const POLICY_CLAIM_EXPENSE_SUCCESS = "POLICY_CLAIM_EXPENSE_SUCCESS";
export const POLICY_CLAIM_EXPENSE_CLEAR = "POLICY_CLAIM_EXPENSE_CLEAR";

export const HR_COVERAGE_LOAD = "HR_COVERAGE_LOAD";
export const HR_COVERAGE_LOAD_FULFILLED = "HR_COVERAGE_LOAD_FULFILLED";
export const HR_COVERAGE_LOAD_REJECTED = "HR_COVERAGE_LOAD_REJECTED";
export const HR_GET_POLICY_SUCCESS = "HR_GET_POLICY_SUCCESS";
export const HR_GET_GA_POLICY_FULFILLED = "HR_GET_GA_POLICY_FULFILLED";
export const HR_GET_INFO_SUCCESS = "HR_GET_INFO_SUCCESS";

export const HR_MY_EMPLOYEE_LOAD = "HR_MY_EMPLOYEE_LOAD";
export const HR_MY_EMPLOYEE_SORT = "HR_MY_EMPLOYEE_SORT";
export const HR_MY_EMPLOYEE_SET_SELECTED_IDS = "HR_MY_EMPLOYEE_SET_SELECTED_IDS";

export const HR_MY_EMPLOYEE_SEND_INVITE_SUCCESS = "HR_MY_EMPLOYEE_SEND_INVITE_SUCCESS";
export const HR_MY_EMPLOYEE_SEND_INVITE_ERROR = "HR_MY_EMPLOYEE_SEND_INVITE_ERROR";
export const HR_MY_EMPLOYEE_CLOSE_POPUP = "HR_MY_EMPLOYEE_CLOSE_POPUP";
export const HR_MY_EMPLOYEE_LOAD_CATEGORY_SUCCESS = "HR_MY_EMPLOYEE_LOAD_CATEGORY_SUCCESS";
export const HR_MY_EMPLOYEE_ADD_SUCCESS = "HR_MY_EMPLOYEE_ADD_SUCCESS";
export const HR_MY_EMPLOYEE_SHOW_LOADING = "HR_MY_EMPLOYEE_SHOW_LOADING";
export const HR_MY_EMPLOYEE_CLOSE_LOADING = "HR_MY_EMPLOYEE_CLOSE_LOADING";
export const HR_GET_USER_INFO_SUCCESS = "HR_GET_USER_INFO_SUCCESS";
export const HR_MY_EMPLOYEE_DEPENDANT_SHOW_LOADING = "HR_MY_EMPLOYEE_DEPENDANT_SHOW_LOADING";
export const HR_MY_EMPLOYEE_DEPENDANT_CLOSE_LOADING = "HR_MY_EMPLOYEE_DEPENDANT_CLOSE_LOADING";
export const HR_MY_EMPLOYEE_DEPENDANT_LOAD = "HR_MY_EMPLOYEE_DEPENDANT_LOAD";

export const HR_GET_BANNER_SUCCESS = "HR_GET_BANNER_SUCCESS";
export const HR_GET_BANNER_ERROR = "HR_GET_BANNER_ERROR";

export const HR_CLAIM_EXPENSE_SHOW_LOADING = "HR_CLAIM_EXPENSE_SHOW_LOADING";
export const HR_CLAIM_EXPENSE_CLOSE_LOADING = "HR_CLAIM_EXPENSE_CLOSE_LOADING";
export const HR_CLAIM_EXPENSE_SHOW_POPUP_SUCCESS = "HR_CLAIM_EXPENSE_SHOW_POPUP_SUCCESS";
export const HR_CLAIM_EXPENSE_SHOW_POPUP_ERROR = "HR_CLAIM_EXPENSE_SHOW_POPUP_ERROR";
export const HR_CLAIM_EXPENSE_CLOSE_POPUP = "HR_CLAIM_EXPENSE_CLOSE_POPUP";
export const HR_CLAIM_EXPENSE_GET_CLAIM_TYPES_SUCCESS = "HR_CLAIM_EXPENSE_GET_CLAIM_TYPES_SUCCESS";
export const HR_CLAIM_EXPENSE_GET_CLAIM_EXPENSES_SUCCESS = "HR_CLAIM_EXPENSE_GET_CLAIM_EXPENSES_SUCCESS";
export const HR_CLAIM_EXPENSE_CLEAR = "HR_CLAIM_EXPENSE_CLEAR";

export const GET_EMAIL_VALIDATION = "GET_EMAIL_VALIDATION";
export const CLIENT_AND_POLICY_LISTING = "CLIENT_AND_POLICY_LISTING";

//Renewal Submission Page
export const RENEW_GET_LIST = "RENEW_GET_LIST";
export const RENEW_GET_LIST_PENDING = "RENEW_GET_LIST_PENDING";
export const RENEW_GET_LIST_FULFILLED = "RENEW_GET_LIST_FULFILLED";
export const RENEW_GET_LIST_REJECTED = "RENEW_GET_LIST_REJECTED";

export const RENEW_GET_SUBMISSION_LIST = "RENEW_GET_SUBMISSION_LIST";
export const RENEW_GET_SUBMISSION_LIST_PENDING = "RENEW_GET_SUBMISSION_LIST_PENDING";
export const RENEW_GET_SUBMISSION_LIST_FULFILLED = "RENEW_GET_SUBMISSION_LIST_FULFILLED";
export const RENEW_GET_SUBMISSION_LIST_REJECTED = "RENEW_GET_SUBMISSION_LIST_REJECTED";

export const GET_RENEW_POLICIES = "GET_RENEW_POLICIES";
export const GET_RENEW_POLICIES_FULFILLED = "GET_RENEW_POLICIES_FULFILLED";
export const GET_RENEW_POLICIES_PENDING = "GET_RENEW_POLICIES_PENDING";
export const SAVE_RENEW_POLICY = "SAVE_RENEW_POLICY";
export const CREATE_RENEW_POLICY = "CREATE_RENEW_POLICY";
export const SET_RENEW_POLICY = "SET_RENEW_POLICY";
export const SET_RENEW_POLICY_TYPE = "SET_RENEW_POLICY_TYPE";
