import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import { List, ListItemIcon, ListItemText, Collapse, ListItemButton } from "@mui/material";
import Drawer from "@mui/material/Drawer";
import Typography from "@mui/material/Typography";
import { withStyles } from "@mui/styles";
import { userActions } from "../actions";
import classNames from "classnames";
import Util from "../utils/Util";
import { getAvaliableProductBenefits, isGrabGroup } from "../selectors/CommonSelectors";
import { ChevronLeft, ChevronRight, ExitToApp, ExpandLess, ExpandMore } from "@mui/icons-material";
import FCCallHelperBottom from "./FCCallHelperBottom";
import NewQuote from "./NewQuote";

class FCMiniLeftDrawer extends Component {
  constructor(props) {
    super(props);
    let { routes } = props;
    this.state = {};
    this.routes = routes;
    this.state = {
      listBenefits: isGrabGroup(props.app.type, props.HR) ? [] : getAvaliableProductBenefits(props.agentType),
      dropdownOpen: false
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.drawerOpen !== this.props.drawerOpen && !this.props.drawerOpen) {
      this.setState({ dropdownOpen: false });
    }
  }

  onLogoutClick = () => {
    this.props.logout();
    this.props.history.push("/");
  };

  handleClick = () => {
    if (this.props.drawerOpen) {
      this.setState(prevState => ({ dropdownOpen: !prevState.dropdownOpen }));
    }
  };

  render() {
    const { drawerOpen, classes, location, userState } = this.props;
    const agentName = Util.getAgentName(userState.auth.agentname, userState.auth.agentgender);

    const userAvatar = (
      <div className={classNames(classes.profile, !drawerOpen && classes.profileClose)}>
        <Typography className={classes.profileTextAgentName}>{agentName ? agentName : ""}</Typography>
        <Typography className={classes.profileTextAgentCode}>FC Code: {userState.username}</Typography>
      </div>
    );

    return (
      <Drawer
        variant="permanent"
        classes={{
          paper: classNames(classes.drawerPaper, !drawerOpen && classes.drawerPaperClose)
        }}
        open={drawerOpen}
      >
        <div className={classes.drawerInner}>
          <List onClick={this.props.handleMenuToggle}>
            <ListItemButton className={classes.menuItemInner}>
              <div className={classNames(classes.drawerHeader, !drawerOpen && classes.drawerHeaderClose)}>
                <ListItemText className={classNames(classes.itemText, !drawerOpen && classes.drawerTitleClose)} />
                {!drawerOpen ? <ChevronRight /> : <ChevronLeft />}
              </div>
            </ListItemButton>
          </List>
          {userAvatar}
          <List>
            {this.routes.map((child, index) => {
              const isActive =
                location.pathname === child.path ||
                (location.pathname.includes("/auth/pi") && child.path.includes("/auth/pi"));
              if (child.menu && child.menu.show) {
                return (
                  <>
                    {child.name !== "Sales Portal" && child.menu.parentName !== "Sales Portal" ? (
                      <Link key={index} to={child.path} className={classes.menuItem}>
                        <ListItemButton className={isActive ? classes.menuItemActive : ""}>
                          <ListItemIcon className={isActive ? classes.iconActive : ""}>
                            {child.menu.icon ? <child.menu.icon /> : ""}
                          </ListItemIcon>
                          <ListItemText
                            primary={child.name}
                            classes={{ primary: isActive ? classes.itemTextActive : classes.itemText }}
                          />
                        </ListItemButton>
                      </Link>
                    ) : (
                      <>
                        {!child.menu.isNested && (
                          <ListItemButton
                            onClick={this.handleClick}
                            className={location.pathname.includes("auth/sp") ? classes.menuItemActive : ""}
                          >
                            <ListItemIcon className={location.pathname.includes("auth/sp") ? classes.iconActive : ""}>
                              {child.menu.icon ? <child.menu.icon /> : ""}
                            </ListItemIcon>
                            <ListItemText
                              primary={child.name}
                              classes={{
                                primary: location.pathname.includes("auth/sp")
                                  ? classes.itemTextActive
                                  : classes.itemText
                              }}
                            />
                            {this.state.dropdownOpen ? <ExpandLess /> : <ExpandMore />}
                          </ListItemButton>
                        )}
                        <Collapse in={this.state.dropdownOpen} timeout="auto" unmountOnExit>
                          <List disablePadding>
                            {child.customComponent === "NewQuote" && (
                              <NewQuote key={`action_${1}`} history={this.props.history} />
                            )}

                            {child.menu &&
                              child.menu.show &&
                              child.customComponent !== "NewQuote" &&
                              child.menu.isNested && (
                                // <Link key={child.path} to={child.path.includes("policyRenewal") ? `${child.path}?id=1` : child.path} className={classes.menuItem}>
                                <Link
                                  key={child.path}
                                  to={child.path.includes("policyRenewal") ? `${child.path}?id=1` : child.path}
                                  className={classes.menuItem}
                                >
                                  <ListItemButton>
                                    <ListItemIcon />
                                    <ListItemText
                                      primary={child.name}
                                      classes={{
                                        primary:
                                          location.pathname === child.path ? classes.itemTextActive : classes.itemText
                                      }}
                                    />
                                  </ListItemButton>
                                </Link>
                              )}
                          </List>
                        </Collapse>
                      </>
                    )}
                  </>
                );
              }
            })}
            <ListItemButton onClick={this.onLogoutClick}>
              <ListItemIcon>{<ExitToApp />}</ListItemIcon>
              <ListItemText primary={"Log Out"} className={classes.itemText} />
            </ListItemButton>
          </List>
          <div className={classes.helplineBottomContent}>
            {drawerOpen ? <FCCallHelperBottom drawerOpen={drawerOpen} /> : ""}
          </div>
        </div>
      </Drawer>
    );
  }
}

const styles = theme => ({
  menuItem: {
    textDecoration: "none",
    height: 40,
    color: "#000"
  },
  profile: {
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    opacity: "1",
    maxHeight: 172,
    paddingBottom: 15,
    marginLeft: 15,
    transition: "all 0.2s linear"
  },
  profileClose: {
    opacity: "0",
    maxHeight: 0
  },
  drawerTitleClose: {
    display: "none"
  },
  drawerPaper: {
    position: "fixed",
    height: "100%",
    marginTop: `64px`,
    [theme.breakpoints.down("sm")]: {
      marginTop: `${theme.mixins.toolbar.minHeight}px`
    },
    width: theme.miniLeftDrawer.width,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerPaperClose: {
    width: theme.miniLeftDrawer.widthClosed,
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  drawerInner: {
    // Make the items inside not wrap when transitioning:
    width: theme.miniLeftDrawer.width,
    display: "flex",
    flexDirection: "column",
    height: `calc(99vh - ${theme.mixins.toolbar.minHeight}px)`
  },
  drawerHeader: {
    display: "flex",
    minHeight: "auto",
    alignItems: "center",
    width: "100%",
    justifyContent: "space-between"
  },
  drawerHeaderClose: {
    justifyContent: "flex-start"
  },
  menuItemActive: {
    backgroundColor: "#F2F2F2 !important",
    borderRight: "7px solid " + `${theme.colors.primary.red}`,
    fontWeight: "bold"
  },
  iconActive: {
    color: `${theme.colors.primary.red}`
  },
  itemText: {
    fontSize: 22
  },
  itemTextActive: {
    fontWeight: "bold"
  },
  profileTextAgentName: {
    fontSize: 18,
    fontWeight: "bold",
    padding: 5,
    color: "black"
  },
  profileTextAgentCode: {
    fontSize: 16,
    padding: 5,
    color: "#6D6D6D"
  },
  helplineBottomContent: {
    marginTop: "auto"
  }
});

function mapStateToProps(state) {
  return {
    coverage: state.HR.coverage,
    userState: state.user,
    quote: state.quote,
    app: state.app,
    agentType: state.user.auth.pcode,
    HR: state.HR
  };
}

function mapDispatchToProps(dispatch) {
  return {
    logout: () => dispatch(userActions.logout())
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(FCMiniLeftDrawer))
);
