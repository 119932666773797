import config from "../config/config";
import { store } from "../store";
import Util from "../utils/Util";

export default class ProductEngineService {
  static getProductConfig() {
    let quote = store.getState().quote;
    return {
      code: quote.product_code,
      version: quote.product_version
    };
  }

  static getProduct() {
    let products = store.getState().products;

    //TODO: find a better way to store this product code stuffs...
    let quote = store.getState().quote;
    return products && products[quote.product_code];
  }

  static validateProduct(categories, opts, ignoreEngineCheck = false) {
    let config = this.getProductConfig();
    let product = this.getProduct();

    let execute = engine => {
      return engine.validateProduct(config.code, config.version, product.config, categories, opts);
    };

    if (!ignoreEngineCheck) {
      return this.isReady().then(engine => {
        let result = execute(engine);
        // console.log("validateProduct - result:", result);
        return Promise.resolve(result);
      });
    } else {
      let engine = window.MMPProducts[config.code];
      let result = execute(engine);
      //console.log("validateProduct - result:", result);
      return result;
    }
  }

  static isReady() {
    let config = this.getProductConfig();
    return new Promise(resolve => {
      let check = () => {
        setTimeout(() => {
          if (!window.MMPProducts) {
            return check();
          } else {
            resolve(window.MMPProducts[config.code]);
          }
        }, 100);
      };

      check();
    });
  }

  static validateCategoryAndEstimate(isDocumentSigned, categories, estimates, opts) {
    let config = this.getProductConfig();
    let product = this.getProduct();

    return this.isReady().then(engine => {
      let result = engine.computeEstimate(config.code, config.version, product.config, categories, estimates, opts);
      //console.log("validateCategoryAndEstimate - result:", result, categories, estimates, opts);
      //ignore check 1207 (Total number of employees), this is only for full list of categories validation
      if (!result.success && result.statusCode !== "1207" && result.statusCode !== "1208") {
        let validation = {
          valid: result.success,
          error: result.msg
        };

        if (isDocumentSigned) {
          const newValue = validation.error.filter(item => {
            item.statusCode !== "1971";
          });
          if (newValue.length == 0) {
            return Promise.resolve(result);
          }
          return Promise.reject(newValue);
        } else {
          return Promise.reject(validation);
        }
      } else {
        return Promise.resolve(result);
      }
    });
  }

  static computeEstimatedPremium(categories, estimates, opts) {
    let config = this.getProductConfig();
    let product = this.getProduct();

    return this.isReady().then(engine => {
      let result = engine.computeEstimate(config.code, config.version, product.config, categories, estimates, opts);
      // console.log("computeEstimatedPremium - result:", result, categories, estimates, opts);
      if (result.success) {
        return Promise.resolve(result);
      } else {
        return Promise.reject(result);
      }
    });
  }

  static computeActualPremium(categories, persons, opts) {
    let config = this.getProductConfig();
    let product = this.getProduct();

    return this.isReady().then(engine => {
      let result = engine.computeActual(config.code, config.version, product.config, categories, persons, opts);
      // console.log("computeActualPremium - result:", result, categories, persons, opts);

      if (result.success) {
        return Promise.resolve(result);
      } else {
        return Promise.reject(result);
      }
    });
  }

  static validateAge(categories, persons, opts, ignoreEngineCheck = false) {
    let config = this.getProductConfig();
    let product = this.getProduct();

    let execute = engine => {
      return engine.validateAge(config.code, config.version, product.config, categories, persons, opts);
    };

    if (!ignoreEngineCheck || Util.isEmpty(window.MMPProducts)) {
      return this.isReady().then(engine => {
        let result = execute(engine);
        //console.log("validateAge - result:", result);
        return Promise.resolve(result);
      });
    } else {
      let engine = window.MMPProducts[config.code];
      let result = execute(engine);
      //console.log("validateAge - result:", result);
      return result;
    }
  }

  static validatePersons(categories, persons, opts) {
    let config = this.getProductConfig();
    let product = this.getProduct();

    return this.isReady().then(engine => {
      let result = engine.validatePersons(config.code, config.version, product.config, categories, persons, opts);
      //console.log("validatePersons - result:", result, categories, persons);

      if (result.success) {
        return Promise.resolve(result);
      } else {
        return Promise.reject(result);
      }
    });
  }
}
