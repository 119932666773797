import { withStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import React, { Component } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";
import classNames from "classnames";

class SimpleControlDialog extends Component {
  render() {
    const {
      closeButtonText,
      closeHandler,
      description,
      okButtonText,
      onClose,
      onSubmit,
      show,
      valid,
      title,
      classes
    } = this.props;

    return (
      <Dialog open={show} onClose={onClose} aria-labelledby="control-dialog-title" disableBackdropClick>
        <form onSubmit={onSubmit}>
          <DialogTitle id="control-dialog-title">{title}</DialogTitle>
          <DialogContent classes={{ root: classes.dialogContent }}>
            <DialogContentText>{description}</DialogContentText>
            {this.props.children}
          </DialogContent>
          <DialogActions classes={{ root: classes.dialogActions }}>
            <Button
              onClick={closeHandler}
              variant="raised"
              className={classNames(classes.button, classes.cancelButton)}
            >
              {closeButtonText}
            </Button>
            <Button
              type="submit"
              disabled={!valid}
              variant="contained"
              color="primary"
              className={classNames(classes.button, classes.okButton)}
            >
              {okButtonText}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  }
}

const styles = theme => ({
  dialogContent: {
    minWidth: 320,
    overflow: "hidden"
  },
  dialogActions: {
    paddingBottom: 15,
    paddingRight: 15
  },
  cancelButton: {
    backgroundColor: theme.colors.secondary.white,
    border: `2px solid ${theme.colors.blackScale.black50}`,
    color: theme.colors.blackScale.black100,
    fontWeight: 500
  },
  button: {
    minWidth: 150
  }
});

export default withStyles(styles, { withTheme: true })(SimpleControlDialog);
