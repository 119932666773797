import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { injectIntl } from "react-intl";

import { withStyles } from "@mui/styles";

import Category from "./Category";
import Util from "../../../../../../utils/Util";
import { quoteActions } from "../../../../../../actions";
import ErrorSummary from "../../../../../../components/ErrorSummary";

class Categories extends Component {
  constructor(props) {
    super(props);

    this.state = {
      validation: null
    };

    this.handlers = {
      computeEstimatedPremium: Util.debounce(200, this.computeEstimatedPremium)
    };
  }

  componentWillMount() {
    this.computeEstimatedPremium();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.quote.data !== nextProps.quote.data) {
      this.handlers.computeEstimatedPremium();
    }
  }

  handleExpandPanel = (id, expanded) => {
    const { categories } = this.props;

    if (categories[id]) {
      categories[id].expandPanel = expanded;
    }

    this.props.setCategories(categories);
  };

  handleInputChange = (id, plan = null, field = null) => event => {
    const { estimates, categories, handleEdits } = this.props;
    let target = event.target;
    let name = target.name;
    let value = field === "enableDependent" ? target.checked : target.value || null;

    let estimate = estimates[id];
    let category = categories[id];
    if (plan) {
      let planConfig = category.plansConfiguration.find(p => {
        return p.code === plan.code;
      });

      planConfig[field] = value;
      //Toggle enableDependent off is tier is null
      if (field === "tier" && Util.isEmpty(value)) {
        planConfig["enableDependent"] = false;
      }

      if (Util.isGFWM(planConfig.code)) {
        switch (field) {
          case "wwc":
            planConfig.wwc = target.checked;
            break;
          case "wpec":
            planConfig.wpec = target.checked;
            break;
          case "wwcNwpec":
            planConfig.wwcNwpec = target.checked;
            break;
          case "tier":
            if (target.value < 1) {
              planConfig.wwc = false;
              planConfig.wpec = false;
              planConfig.wwcNwpec = false;
            }
          default:
            //do nothing
            break;
        }
      }

      if (field == "enableGAMR") {
        let gamrConfiguration = category.plansConfiguration.find(p => {
          return p.code == "GAMR";
        });

        if (target.checked) {
          let gaddConfiguration = category.plansConfiguration.find(p => {
            return p.code == "GADD";
          });
          gamrConfiguration.tier = gaddConfiguration.tier;
        } else {
          gamrConfiguration.tier = null;
        }
      }

      let productPlans = this.togglePlanDisplay(category);

      productPlans.map(productPlan => {
        let planConfig = category.plansConfiguration.find(p => {
          return p.code === productPlan.code;
        });
        if (!productPlan.show || !productPlan.tiers.includes(planConfig.tier)) {
          planConfig.tier = null;
          planConfig.enableDependent = false;
        }
        if (!productPlan.showDependent) {
          planConfig.enableDependent = false;
        }
      });
    } else {
      if (name === "name") {
        category.name = value;
      }
      if (field === "noEmployee" || field === "avgAge") {
        estimate[name] = value || 0;
      } else {
        estimate[name] = value;
      }
    }
    this.props.setEstimates(estimates);
    this.props.setCategories(categories);
    handleEdits();
  };

  togglePlanDisplay = category => {
    const { product } = this.props;

    let productPlans = Util.clone(product.config.plans);

    // toggle plan show / hide on UI for PF3
    if (!Util.isProductPF3(product.config.code)) {
      productPlans.map(plan => {
        plan.show = true;
        plan.showDependent = true;
      });
      return productPlans;
    }

    let gtlbConfig = category.plansConfiguration.find(p => {
      return p.code === "GTLB";
    });
    let ghsbConfig = category.plansConfiguration.find(p => {
      return p.code === "GHSB";
    });
    let gfwmConfig = category.plansConfiguration.find(p => {
      return p.code === "GFWM";
    });

    // always show core plan
    productPlans.map(plan => {
      plan.show = false;

      if (plan.type === "core") {
        plan.show = true;
        plan.showDependent = true;
      } else {
        plan.showDependent = gtlbConfig.enableDependent || ghsbConfig.enableDependent;
      }
    });

    // GTLB Display logic
    // show GCCA when GTLB is selected
    productPlans.map(plan => {
      if (plan.code === "GCCA") {
        plan.show = gtlbConfig.tier ? true : false;
        plan.tiers = [gtlbConfig.tier];
        plan.showDependent = gtlbConfig.enableDependent;
      }
    });

    // show GEAC when GCCA is selected
    let gccaConfig = category.plansConfiguration.find(p => {
      return p.code === "GCCA";
    });
    productPlans.map(plan => {
      if (plan.code === "GEAC") {
        plan.show = gtlbConfig.tier && gccaConfig.tier ? true : false;
        plan.tiers = [gtlbConfig.tier];
        plan.showDependent = gtlbConfig.enableDependent && gccaConfig.enableDependent;
      }
    });

    // GHSB / GFWM Display logic
    // show GEMM, GDEN, PDIH when GADD is selected
    productPlans.map(plan => {
      if (plan.code === "GDEN" || plan.code === "PDIH") {
        plan.show = ghsbConfig.tier || gfwmConfig.tier ? true : false;
        plan.showDependent = ghsbConfig.enableDependent;
      }
      if (plan.code === "GEMM" && ghsbConfig.tier >= 1 && ghsbConfig.tier <= 5) {
        plan.show = true;
        plan.showDependent = ghsbConfig.enableDependent;
        plan.tiers = [ghsbConfig.tier];
      }
    });

    // Applicable for both GTLB and GHSB
    // show PGIH / GADD when GTLB _OR_ GHSB _OR_ GFWM is selected
    productPlans.map(plan => {
      if (plan.code === "PGIH" || plan.code === "GADD") {
        plan.show = ghsbConfig.tier || gtlbConfig.tier || gfwmConfig.tier ? true : false;
        plan.showDependent = ghsbConfig.enableDependent || gtlbConfig.enableDependent;
      }
    });

    // show PSIH when
    //  GTLB is selected _OR_ GHSB && PGIH is selected
    let pgihConfig = category.plansConfiguration.find(p => {
      return p.code === "PGIH";
    });
    productPlans.map(plan => {
      if (plan.code === "PSIH") {
        plan.show = (pgihConfig.tier && (ghsbConfig.tier || gfwmConfig.tier)) || gtlbConfig.tier ? true : false;
        plan.showDependent = (pgihConfig.enableDependent && ghsbConfig.enableDependent) || gtlbConfig.enableDependent;
      }
    });

    // show GAMR when GADD is selected
    let gaddConfig = category.plansConfiguration.find(p => {
      return p.code === "GADD";
    });
    productPlans.map(plan => {
      if (plan.code === "GAMR") {
        plan.show = gaddConfig.tier && (ghsbConfig.tier || gtlbConfig.tier || gfwmConfig.tier) ? true : false;
        plan.tiers = [gaddConfig.tier];
        plan.showDependent = gaddConfig.enableDependent && (ghsbConfig.enableDependent || gtlbConfig.enableDependent);
      }
    });

    return productPlans;
  };

  toggleEstimateErrorDisplay = (estimate, idx) => {
    // clear all field error
    estimate.noOfEmployeeError = null;
    estimate.averageAgeError = null;
    estimate.nameError = null;

    if (!this.state.validation) {
      return estimate;
    }

    // error affecting all estimates (no category prefix)
    let estimateError = this.state.validation.map(error => {
      return error.statusCode;
    });

    if (estimateError.includes("1208")) {
      estimate.noOfEmployeeError = true;
    }

    if (estimateError.includes("1116") || estimateError.includes("1113")) {
      estimate.nameError = true;
    }

    // error affecting particular estimate
    let individualEstimateError = this.state.validation
      .filter(error => {
        // if (Array.isArray(error.msg)){
        return error.msg.startsWith("Category");
        // }
      })
      .filter(error => {
        let categoryIndex = error.msg.match(/\d+/)[0];
        return categoryIndex - 1 == idx;
      })
      .map(error => {
        return error.statusCode;
      });

    if (individualEstimateError.includes("1206")) {
      estimate.averageAgeError = true;
    }

    if (individualEstimateError.includes("1207") || individualEstimateError.includes("1204")) {
      estimate.noOfEmployeeError = true;
    }

    // error affecting cumulative estimate
    let cumulativeEstimateError = this.state.validation
      .filter(error => {
        return !error.msg.startsWith("Category");
      })
      .map(error => {
        return error.statusCode;
      });

    if (cumulativeEstimateError.includes("1207")) {
      estimate.noOfEmployeeError = true;
    }

    return estimate;
  };

  toggleCategoryErrorDisplay = (category, idx) => {
    category.plansConfiguration.forEach(plan => {
      plan.showError = null;
    });

    if (!this.state.validation) {
      return category;
    }

    let individualCategoryError = this.state.validation
      .filter(error => {
        // if (Array.isArray(error.msg)){
        return error.msg.startsWith("Category");
        // }
      })
      .filter(error => {
        let categoryIndex = error.msg.match(/\d+/)[0];
        return categoryIndex - 1 == idx;
      })
      .map(error => {
        return error.statusCode;
      });

    if (individualCategoryError.includes("1102")) {
      category.plansConfiguration
        .filter(plan => {
          return plan.code == "GTLB" || plan.code == "GHSB" || Util.isGFWM(plan.code);
        })
        .forEach(plan => {
          plan.showError = true;
        });
    }

    if (individualCategoryError.includes("1117")) {
      category.plansConfiguration
        .filter(plan => {
          return plan.code == "GHSB" || Util.isGFWM(plan.code);
        })
        .forEach(plan => {
          plan.showError = true;
        });
    }

    if (individualCategoryError.includes("1321")) {
      category.plansConfiguration
        .filter(plan => {
          return plan.code == "GDEN" || plan.code == "PDIH";
        })
        .forEach(plan => {
          plan.showError = true;
        });
    }

    if (individualCategoryError.includes("1105") || individualCategoryError.includes("1107")) {
      category.plansConfiguration
        .filter(plan => {
          return plan.code == "PGIH" || plan.code == "PSIH";
        })
        .forEach(plan => {
          plan.showError = true;
        });
    }

    return category;
  };

  handleToggleAllowDependents = id => event => {
    const { categories, productPlans, handleEdits } = this.props;
    let target = event.target;
    categories[id].enableDependent = target.checked;
    productPlans.forEach(plan => {
      let planConfig = categories[id].plansConfiguration.find(item => item.code === plan.code);
      planConfig["enableDependent"] = false;
    });
    this.props.setCategories(categories);
    handleEdits();
  };

  handleRemoveCategory = id => {
    const { estimates, categories, handleEdits } = this.props;
    estimates.splice(id, 1);
    categories.splice(id, 1);
    this.props.setEstimates(estimates);
    this.props.setCategories(categories);
    handleEdits();
  };

  isEmptyCategories = () => {
    const { estimates, categories } = this.props;
    let isEmpty = true;

    estimates.every((estimate, idx) => {
      let category = categories[idx];
      if (!isEmpty) return false;

      let hasPlansSelection =
        category.plansConfiguration.filter(c => !Util.isEmpty(c.tier) || c.enableDependent).length > 0;
      isEmpty = !(!Util.isEmpty(category.name) || hasPlansSelection || !Util.hasEmptyValues(estimate));
      return true;
    });
    return isEmpty;
  };

  computeEstimatedPremium = () => {
    const { intl } = this.props;

    //Check if there are any categories worth validating against
    this.props.computeEstimatedPremium().then(
      resp => {
        this.setState({ validation: null });
      },
      error => {
        let errorList = [
          {
            ...error,
            title: intl.formatMessage({ id: "validator.premiums.error" })
          }
        ];

        // multiple errors from engine : validateProductBulk
        if (Array.isArray(error.error)) {
          error.error.map(e => {
            e.msg = Util.mapPlansCodeLabel(e.msg);
          });
          errorList = [...error.error];
        }

        let err = this.isEmptyCategories() ? null : errorList;

        if (this.isEmptyCategories()) {
          if (errorList.find(u => u.statusCode === "1971" || u.statusCode === "1973")) {
            if (!this.props.document.hasSignedProposal) {
              this.setState({ validation: [errorList.find(u => u.statusCode === "1971" || u.statusCode === "1973")] });
            }
          } else {
            this.setState({ validation: [] });
          }
        } else {
          if (errorList.find(u => u.statusCode === "1971" || u.statusCode === "1973")) {
            if (this.props.document.hasSignedProposal) {
              const nbstpFilter = err.filter(item => {
                item.statusCode !== "1971" || u.statusCode !== "1973";
              });
              this.setState({ validation: nbstpFilter });
            } else {
              this.setState({ validation: err });
            }
          } else {
            this.setState({ validation: err });
          }
        }
      }
    );
  };

  setStateFromProps = props => {
    let product = props.product;
    let categories = props.quote.data.categories || [];
    let estimates = props.quote.data.estimates || [];
    let plans = (product && product.config && product.config.plans) || [];

    this.setState(
      {
        plans: plans,
        readOnly: this.props.quote.readOnly || props.document.hasSignedProposal
      },
      () => {
        this.setAndValidate(estimates, categories);
      }
    );
  };

  render() {
    const { classes, estimates, categories, quote, document } = this.props;
    const { validation } = this.state;

    return (
      <div className={classes.root}>
        <ErrorSummary errors={validation} />

        {estimates.map((estimate, idx) => {
          let category = categories[idx];
          let filteredProductPlan = this.togglePlanDisplay(category);

          // Hide GAMR, it is displayed as a swich under GADD
          filteredProductPlan.map(plan => {
            if (plan.code == "GAMR") {
              plan.show = false;
            }
          });

          let errorIndicatedCategory = this.toggleCategoryErrorDisplay(category, idx);
          let errorIndicatedEstimate = this.toggleEstimateErrorDisplay(estimate, idx);
          let categoryTitle = "Category " + (idx + 1);
          return (
            <Category
              key={idx}
              id={idx}
              readOnly={quote.readOnly || document.hasSignedProposal}
              estimate={errorIndicatedEstimate}
              categoryTitle={categoryTitle}
              category={errorIndicatedCategory}
              plans={filteredProductPlan}
              expandPanel={category.expandPanel}
              handleRemoveCategory={this.handleRemoveCategory}
              handleToggleAllowDependents={this.handleToggleAllowDependents}
              handleToggleDependentPlan={this.handleToggleDependentPlan}
              handleInputChange={this.handleInputChange}
              handleExpandPanel={this.handleExpandPanel}
            />
          );
        })}
      </div>
    );
  }
}

const styles = theme => ({
  root: {},
  hintText: {
    color: "#999",
    paddingBottom: theme.spacing.unit * 2
  }
});

function mapStateToProps(state) {
  let product = state.products[state.quote.product_code];

  let categories = state.quote.data.categories;
  categories.map(category => {
    category.expandPanel = category.expandPanel ? category.expandPanel : false;
  });

  return {
    quote: state.quote,
    categories: categories,
    estimates: state.quote.data.estimates,
    productPlans: product && product.config.plans,
    document: state.document,
    product: product
  };
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(quoteActions, dispatch)
  };
}

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(Categories))
);
