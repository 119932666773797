import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import { connect } from "react-redux";
import Icon from "@mui/material/Icon";
import { Link } from "react-router-dom";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import { userActions } from "../actions";
import { withRouter } from "react-router";
import { withStyles } from "@mui/styles";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CallHelperBottom from "../components/hr/menu/CallHelperBottom";
import classNames from "classnames";
import Drawer from "@mui/material/Drawer";
import NewQuote from "./NewQuote";
import React, { Component } from "react";
import Typography from "@mui/material/Typography";
import config from "../config/config";
import Enums from "../utils/Enums";
import Util from "../utils/Util";
import IconLogout from "../assets/svg/Logout";

class MiniLeftDrawer extends Component {
  constructor(props) {
    super(props);
    let { routes } = props;
    this.state = {};
    this.routes = routes;
  }

  onLogoutClick = () => {
    this.props.logout();
    this.props.history.push("/");
  };

  onHomeClick = () => {
    this.props.history.push("/auth/home");
  };

  /**
   * Depends on which type we will render it
   * Right now we have 2 types: icon and image
   * This function for working on mmp-171
   */
  renderIcon = (menu, isActive = false) => {
    const { classes } = this.props;

    if (menu.svg) {
      return <menu.svg color={isActive ? "primary" : "inherit"} fontSize="medium" />;
    }

    return menu.icon !== undefined ? (
      <Icon>{menu.icon}</Icon>
    ) : (
      <Avatar
        src={menu.image}
        alt={menu.name}
        className={classes.iconImageWrapper}
        imgProps={{ className: classes.iconImage }}
      />
    );
  };

  render() {
    // const {avatar,  drawerOpen, classes, location} = this.props;
    const { drawerOpen, classes, location, userState, app, coverage, theme } = this.props;
    const agentName = Util.getAgentName(userState.auth.agentname, userState.auth.agentgender);
    const filteredRoute = this.routes.filter(
      route => userState.auth.scope.includes(route.access) || route.access === undefined
    );

    const userAvatar = (
      <div className={classNames(classes.profile, !drawerOpen && classes.profileClose)}>
        {config.app.type !== Enums.APP_TYPE.SALES && (
          <Typography
            className={classes.profileText}
            sx={{
              fontWeight: config.app.type === Enums.APP_TYPE.HR ? "bold" : "normal"
            }}
          >
            {Util.showUserName(userState, config.app.type)}
          </Typography>
        )}

        {config.app.type === Enums.APP_TYPE.SALES && location.pathname === "/auth/dashboard" && (
          <>
            <Typography className={classes.profileTextSales}>FC Code: {userState.username}</Typography>
            <Typography className={classes.profileTextSales}>{agentName}</Typography>
          </>
        )}

        {/* <Avatar src={Avatar} className={classes.profileAvatar}/>
        <Typography className={classes.profileText}>
          {user.firstname}
          {user.lastname}
          <br/> {user.designation}
        </Typography> */}
        {config.app.type === Enums.APP_TYPE.SALES && (
          <div className={classes.profileActions}>
            <Button variant="contained" color="primary" className={classes.button} onClick={this.onLogoutClick}>
              Log out
            </Button>
          </div>
        )}
      </div>
    );

    return (
      <Drawer
        variant="permanent"
        classes={{
          paper: classNames(classes.drawerPaper, !drawerOpen && classes.drawerPaperClose)
        }}
        open={drawerOpen}
      >
        <div className={classes.drawerInner}>
          <MenuList onClick={this.props.handleMenuToggle}>
            <MenuItem className={classes.menuItemInner}>
              <div className={classNames(classes.drawerHeader, !drawerOpen && classes.drawerHeaderClose)}>
                <ListItemText className={classNames(classes.itemText, !drawerOpen && classes.drawerTitleClose)} />
                {!drawerOpen ? <ChevronRight /> : <ChevronLeft />}
              </div>
            </MenuItem>
          </MenuList>

          {userAvatar}

          <MenuList className={classes.list}>
            {/* <MenuItem
                className={classNames(classes.showItem, drawerOpen && classes.hideItem)}>
              <Avatar src={avatar} className={classes.menuItemAvatar}/>
            </MenuItem> */}
            {filteredRoute.map((child, index) => {
              if (child.customComponent === "NewQuote") {
                return <NewQuote key={`action_${1}`} history={this.props.history} />;
              }
              if (
                child.menu &&
                child.menu.show
                // &&
                // ((coverage &&
                //   coverage.policy &&
                //   !Util.isCompDisabledForCode(coverage.policy.product_code, child.name.toUpperCase())) ||
                //   !coverage ||
                //   !coverage.policy)
              ) {
                //If there are no benefits available for the user, hide the menu as well
                // if (
                //   child.menu.validate &&
                //   child.menu.validate.employeeBenefit &&
                //   Util.isEmpty(listBenefits)
                // ) {
                //   return null;
                // }

                const { routes: childRoutes } = child;
                let isActive =
                  location.pathname === child.path ||
                  (location.pathname.includes("employeeBenefit") && child.path.includes("employeeBenefit"));

                if (childRoutes) {
                  for (const childRoute of childRoutes) {
                    if (childRoute.path === location.pathname) {
                      isActive = true;
                    }
                  }
                }

                return (
                  <Link key={index} to={child.path} className={classes.menuItem}>
                    <MenuItem
                      classes={{ root: config.app.type === Enums.APP_TYPE.HR ? classes.menuItemHR : classes.menuItem }}
                      className={classNames(isActive ? classes.menuItemActive : "")}
                    >
                      <ListItemIcon className={classes.icon}>{this.renderIcon(child.menu, isActive)}</ListItemIcon>
                      <ListItemText
                        primary={child.name}
                        classes={{
                          primary:
                            isActive & (config.app.type === Enums.APP_TYPE.HR)
                              ? classes.itemTextActive
                              : classes.itemText
                        }}
                        className={classes.itemTextWrapper}
                      />
                    </MenuItem>
                  </Link>
                );
              } else return null;
            })}

            {config.app.type === Enums.APP_TYPE.HR && (
              <MenuItem classes={{ root: classes.menuItem }} onClick={this.onLogoutClick}>
                <ListItemIcon className={classes.icon}>
                  <IconLogout />
                </ListItemIcon>
                <ListItemText
                  primary="Log Out"
                  classes={{ primary: classes.itemText }}
                  className={classes.itemTextWrapper}
                />
              </MenuItem>
            )}
          </MenuList>
          <CallHelperBottom drawerOpen={drawerOpen} />
        </div>
      </Drawer>
    );
  }
}

const styles = theme => ({
  menuItem: {
    textDecoration: "none",
    height: 40,
    color: "#000"
  },
  menuItemHR: {
    textDecoration: "none",
    height: 40,
    color: "#000",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },
  profile: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    opacity: "1",
    maxHeight: 172,
    paddingBottom: 15,
    transition: "all 0.2s linear"
  },
  profileClose: {
    opacity: "0",
    maxHeight: 0
  },
  profileAvatar: {
    width: 80,
    height: 80
  },
  profileText: {
    textAlign: "center",
    paddingBottom: 20,
    fontSize: 24,
    padding: 10
  },
  profileActions: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-around"
  },
  drawerTitleClose: {
    display: "none"
  },
  drawerPaper: {
    position: "fixed",
    height: "100%",
    marginTop: `64px`,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      marginTop: `${theme.mixins.toolbar.minHeight}px`
    },
    width: theme.miniLeftDrawer.width,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerPaperClose: {
    width: theme.miniLeftDrawer.widthClosed,
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  drawerInner: {
    // Make the items inside not wrap when transitioning:
    width: theme.miniLeftDrawer.width,
    display: "flex",
    flexDirection: "column",
    height: "100%"
  },
  drawerHeader: {
    display: "flex",
    minHeight: "auto",
    alignItems: "center",
    width: "100%",
    justifyContent: "space-between"
  },
  drawerHeaderClose: {
    justifyContent: "flex-start"
  },
  list: {
    flexGrow: 1
  },
  showItem: {
    maxHeight: 24,
    opacity: 1
  },
  hideItem: {
    maxHeight: 0,
    opacity: 0
  },
  menuItemActive: {
    backgroundColor: "#F2F2F2",
    borderRight: "7px solid " + `${theme.colors.primary.red}`
  },
  button: {
    boxShadow: "none",
    backgroundColor: `${theme.colors.primary.red}`,
    fontSize: 18,
    height: 51,
    width: 227,
    borderRadius: 25.5,
    margin: "0 20px"
  },
  iconImageWrapper: {
    overflow: "unset",
    width: "unset",
    height: "unset"
  },
  iconImage: {
    width: 20
    // height: 28
  },
  itemText: {
    fontSize: 22
  },
  itemTextActive: {
    fontSize: 22,
    fontWeight: "bold"
  },
  profileTextSales: {
    textAlign: "center",
    fontSize: 22,
    padding: 5,
    color: "black"
  }
});

function mapStateToProps(state) {
  return {
    coverage: state.HR.coverage,
    userState: state.user,
    quote: state.quote,
    app: state.app,
    HR: state.HR
  };
}

function mapDispatchToProps(dispatch) {
  return {
    logout: () => dispatch(userActions.logout())
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(MiniLeftDrawer))
);
